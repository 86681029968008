import fetch from 'auth/FetchInterceptor'

const leaveService = {}

leaveService.getLeaves = function (params) {
  return fetch({
    url: '/leaves',
    method: 'get',
    params
  })
}


leaveService.getStaffLeaves = function (staffId, params) {
  return fetch({
    url: `/leaves/staffs/${staffId}`,
    method: 'get',
    params
  })
}


leaveService.requestLeave = function (data) {
  console.log(data)
  return fetch({
    url: '/leaves',
    method: 'post',
    data: data
  })
}

leaveService.updateLeave = function (id, data) {
  return fetch({
    url: `/leaves/${id}`,
    method: 'put',
    data: data
  })
}



leaveService.approveLeave = function (id, data) {
  return fetch({
    url: `/leaves/${id}/approve`,
    method: 'put',
    data: data
  })
}



leaveService.rejectLeave = function (id, data) {
  return fetch({
    url: `/leaves/${id}/reject`,
    method: 'put',
    data: data
  })
}


leaveService.getHolidays = function (params) {
  return fetch({
    url: '/leaves/holidays',
    method: 'get',
    params
  })
}


export default leaveService