import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { CREATE_A_MEETING, JOIN_A_MEETING, END_A_MEETING, GET_USER_MEETINGS, GET_CALENDAR_MEETINGS } from "../constants/Meeting";
import { createMeetingSuccess, joinMeetingSuccess, getUserMeetingsSuccess, endMeetingSuccess, getCalendarMeetingsSuccess } from "../actions/Meeting";

import meetingService from "services/MeetingService";

export function* createMeeting() {
  yield takeEvery(CREATE_A_MEETING, function* ({ payload }) {
    try {
      const meeting = yield call(meetingService.createMeeting, payload);
      if (meeting.error) {
        // yield put(showAuthMessage(user.error.message));
      } else {
        yield put(createMeetingSuccess(meeting.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}



export function* joinMeeting() {
  yield takeEvery(JOIN_A_MEETING, function* ({ payload }) {
    try {
      const meeting = yield call(meetingService.joinMeeting, payload);
      if (meeting.error) {
        // yield put(showAuthMessage(user.error.message));
      } else {
        yield put(joinMeetingSuccess(meeting.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}



export function* getUserMeetings() {


	yield takeEvery(GET_USER_MEETINGS, function* ({ payload }) {
	  try {
		const meetings = yield call(meetingService.getMeetings, payload);
		if (meetings.error) {

		  // yield put(showAuthMessage(user.message));
		} else {
		  yield put(getUserMeetingsSuccess(meetings.data));
		}
	  } catch (err) {
		console.log(err);
		// yield put(showAuthMessage(err));
	  }
	});
  }



  export function* endMeeting() {
    yield takeEvery(END_A_MEETING, function* ({ payload }) {
      try {
        const meeting = yield call(meetingService.endMeeting, payload);
        if (meeting.error) {
          // yield put(showAuthMessage(user.error.message));
        } else {
          yield put(endMeetingSuccess(meeting.data));
        }
      } catch (err) {
        console.log(err);
        // yield put(showAuthMessage(err));
      }
    });
  }





  export function* getCalendarMeetings() {
    yield takeEvery(GET_CALENDAR_MEETINGS, function* ({  }) {
      try {
      const meetings = yield call(meetingService.getCalendarMeetings);
      if (meetings.error) {
  
        // yield put(showAuthMessage(user.message));
      } else {
        yield put(getCalendarMeetingsSuccess(meetings.data));
      }
      } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
      }
    });
    }

export default function* rootSaga() {
  yield all([fork(createMeeting), fork(joinMeeting), fork(getUserMeetings), fork(endMeeting), fork(getCalendarMeetings)]);
}
