import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { ADD_NOTICE, GET_NOTICES } from "../constants/NoticeBoard";
import {
  addNoticeSuccess,
  getNoticesSuccess,
  showErrorMessage,
} from "../actions/NoticeBoard";

import noticeService from "services/NoticeBoardService";

export function* addNotice() {
  yield takeEvery(ADD_NOTICE, function* ({ payload }) {
    try {
      const notice = yield call(noticeService.addNotice, payload);
      if (notice.error) {
        yield put(showErrorMessage(notice.error.message));
      } else {
        yield put(addNoticeSuccess(notice.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}

export function* getNotices() {
  yield takeEvery(GET_NOTICES, function* () {
    try {
      const notices = yield call(noticeService.getNotices);
      if (notices.error) {
        // yield put(showAuthMessage(user.message));
      } else {
        yield put(getNoticesSuccess(notices.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}



export default function* rootSaga() {
  yield all([fork(addNotice), fork(getNotices)]);
}
