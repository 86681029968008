import React from "react";
import GooglePicker from "./google-drive";
import { Button } from "antd";

const CLIENT_ID =
  "95586151270-b8ehuplrvcrrvets74cfegpt97qp5o0f.apps.googleusercontent.com";
const DEVELOPER_KEY = "AIzaSyCJxP0oHg6CoMcVyLQOu-IY2ecMD_efdBs";
const SCOPE = ["https://www.googleapis.com/auth/drive"];

export default function FileManager({
  pickerCallback,
  enableUploadView = true,
  ...props
}) {
  return (
    <GooglePicker
      clientId={CLIENT_ID}
      developerKey={DEVELOPER_KEY}
      scope={SCOPE}
      // onChange={(data) => console.log("on change:", data)}
      onAuthFailed={(data) => console.log("on auth failed:", data)}
      multiselect={true}
      navHidden={false}
      authImmediate={false}
      viewId={"DOCUMENTS"}
      onChange={pickerCallback}
      createPicker={(google, oauthToken) => {
        const googleViewId = google.picker.ViewId.DOCUMENTS;
        const docsView = new google.picker.DocsView(googleViewId)
          .setIncludeFolders(true)
          .setMimeTypes("application/vnd.google-apps.folder")
          .setSelectFolderEnabled(true);

        const picker = new window.google.picker.PickerBuilder()
          .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)

          .enableFeature(google.picker.Feature.SUPPORT_DRIVES)
          .addView(docsView)

          .setOAuthToken(oauthToken)
          .setDeveloperKey(DEVELOPER_KEY)
          .setCallback(pickerCallback);

        if (enableUploadView) {
          const uploadView =
            new google.picker.DocsUploadView().setIncludeFolders(true);
          picker.addView(uploadView);
        }

        picker.build().setVisible(true);
      }}
    >
      {props.children}
    </GooglePicker>
  );
}


