import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { GET_ROOM_CHATS, GET_USER_ROOMS, INITIATE_CHAT } from "../constants/Chat";
import { getUserRoomsSuccess, getRoomChatsSuccess, initiateChatSuccess, updateRoomWithNewRoom } from "../actions/Chat";

import chatService from "services/ChatService";

export function* getUserRooms() {
  yield takeEvery(GET_USER_ROOMS, function* ({ userId, params }) {
    try {
      const rooms = yield call(chatService.getUserRooms, userId, params);
      if (rooms.error) {
        // yield put(showAuthMessage(user.error.message));
      } else {
        yield put(getUserRoomsSuccess(rooms.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}


export function* getRoomChats() {


	yield takeEvery(GET_ROOM_CHATS, function* ({roomId}) {
	  try {
		const chats = yield call(chatService.getRoomChats,roomId );
		if (chats.error) {

		  // yield put(showAuthMessage(user.message));
		} else {
		  yield put(getRoomChatsSuccess(chats.data));
		}
	  } catch (err) {
		console.log(err);
		// yield put(showAuthMessage(err));
	  }
	});
  }


  export function* initateChat() {
    yield takeEvery(INITIATE_CHAT, function* ({ payload }) {
      try {
        const room = yield call(chatService.initiateChat, payload);
        if (room.error) {
          // yield put(showAuthMessage(user.error.message));
        } else {
          yield put(updateRoomWithNewRoom(room.data));
        }
      } catch (err) {
        console.log(err);
        // yield put(showAuthMessage(err));
      }
    });
  }

export default function* rootSaga() {
  yield all([fork(getUserRooms), fork(getRoomChats), fork(initateChat)]);
}
