import Icon, {
  ProfileOutlined,
  UserOutlined,
  UserAddOutlined,
  ProjectOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "../configs/AppConfig";

const TaskSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 286 271"
    focusable="false"
    fill="currentColor"
    aria-hidden="true"
  >
    <path
      d="M53.76 159.2H79.36C80.768 159.2 81.92 158.305 81.92 157.21V41.79C81.92 40.6955 80.768 39.8 79.36 39.8H53.76C52.352 39.8 51.2 40.6955 51.2 41.79V157.21C51.2 158.305 52.352 159.2 53.76 159.2ZM115.2 89.55H140.8C142.208 89.55 143.36 88.6545 143.36 87.56V41.79C143.36 40.6955 142.208 39.8 140.8 39.8H115.2C113.792 39.8 112.64 40.6955 112.64 41.79V87.56C112.64 88.6545 113.792 89.55 115.2 89.55ZM176.64 107.46H202.24C203.648 107.46 204.8 106.565 204.8 105.47V41.79C204.8 40.6955 203.648 39.8 202.24 39.8H176.64C175.232 39.8 174.08 40.6955 174.08 41.79V105.47C174.08 106.565 175.232 107.46 176.64 107.46ZM245.76 0H10.24C4.576 0 0 3.55712 0 7.96V191.04C0 195.443 4.576 199 10.24 199H245.76C251.424 199 256 195.443 256 191.04V7.96C256 3.55712 251.424 0 245.76 0ZM232.96 181.09H23.04V17.91H232.96V181.09Z"
      fill="currentColor"
    />
    <path
      d="M278.742 239.366C274.101 229.711 267.367 220.941 258.914 213.544C250.486 206.126 240.503 200.212 229.516 196.128C229.417 196.085 229.319 196.063 229.221 196.02C244.547 186.296 254.51 170.458 254.51 152.588C254.51 122.985 227.203 99 193.5 99C159.797 99 132.49 122.985 132.49 152.588C132.49 170.458 142.453 186.296 157.779 196.042C157.681 196.085 157.583 196.107 157.484 196.15C146.463 200.234 136.574 206.089 128.086 213.566C119.641 220.968 112.908 229.737 108.258 239.387C103.69 248.836 101.226 258.965 101.001 269.228C100.994 269.459 101.04 269.688 101.136 269.903C101.232 270.118 101.376 270.313 101.559 270.479C101.743 270.644 101.962 270.775 102.204 270.864C102.446 270.954 102.706 271 102.969 271H117.729C118.812 271 119.673 270.244 119.697 269.315C120.189 252.633 127.816 237.011 141.297 225.169C155.246 212.918 173.77 206.176 193.5 206.176C213.23 206.176 231.754 212.918 245.703 225.169C259.184 237.011 266.811 252.633 267.303 269.315C267.327 270.265 268.188 271 269.271 271H284.031C284.294 271 284.554 270.954 284.796 270.864C285.038 270.775 285.257 270.644 285.441 270.479C285.624 270.313 285.768 270.118 285.864 269.903C285.96 269.688 286.006 269.459 285.999 269.228C285.753 258.899 283.318 248.852 278.742 239.366V239.366ZM193.5 189.754C182.208 189.754 171.581 185.886 163.585 178.863C155.59 171.841 151.186 162.506 151.186 152.588C151.186 142.67 155.59 133.335 163.585 126.313C171.581 119.29 182.208 115.422 193.5 115.422C204.792 115.422 215.419 119.29 223.415 126.313C231.41 133.335 235.814 142.67 235.814 152.588C235.814 162.506 231.41 171.841 223.415 178.863C215.419 185.886 204.792 189.754 193.5 189.754Z"
      fill="currentColor"
    />
  </svg>
);

const StaffsSvg = () => (
  <svg
    viewBox="0 0 274 176"
    focusable="false"
    data-icon="staffs"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
  >
    <path
      d="M266.938 143.63C262.423 133.751 255.871 124.777 247.646 117.208C239.446 109.618 229.733 103.566 219.042 99.3869C218.947 99.3427 218.851 99.3206 218.755 99.2764C233.667 89.3266 243.361 73.1196 243.361 54.8342C243.361 24.5427 216.792 0 184 0C151.208 0 124.639 24.5427 124.639 54.8342C124.639 73.1196 134.333 89.3266 149.245 99.2985C149.149 99.3427 149.053 99.3648 148.958 99.409C138.234 103.588 128.612 109.58 120.354 117.23C112.137 124.805 105.586 133.777 101.062 143.652C96.6173 153.32 94.2204 163.685 94.0006 174.187C93.9942 174.423 94.039 174.658 94.1324 174.877C94.2258 175.097 94.3658 175.297 94.5442 175.466C94.7227 175.635 94.936 175.77 95.1715 175.861C95.407 175.953 95.6599 176 95.9155 176H110.277C111.33 176 112.168 175.226 112.192 174.275C112.671 157.206 120.091 141.22 133.208 129.104C146.78 116.567 164.803 109.668 184 109.668C203.197 109.668 221.22 116.567 234.792 129.104C247.909 141.22 255.329 157.206 255.808 174.275C255.832 175.248 256.67 176 257.723 176H272.085C272.34 176 272.593 175.953 272.829 175.861C273.064 175.77 273.277 175.635 273.456 175.466C273.634 175.297 273.774 175.097 273.868 174.877C273.961 174.658 274.006 174.423 273.999 174.187C273.76 163.618 271.39 153.337 266.938 143.63V143.63ZM184 92.8643C173.013 92.8643 162.673 88.9065 154.894 81.7206C147.115 74.5347 142.83 64.9829 142.83 54.8342C142.83 44.6854 147.115 35.1337 154.894 27.9477C162.673 20.7618 173.013 16.804 184 16.804C194.987 16.804 205.327 20.7618 213.106 27.9477C220.885 35.1337 225.17 44.6854 225.17 54.8342C225.17 64.9829 220.885 74.5347 213.106 81.7206C205.327 88.9065 194.987 92.8643 184 92.8643Z"
      fill="currentColor"
    />
    <path
      d="M165.252 139.55C160.938 129.951 154.676 121.232 146.817 113.878C138.982 106.504 129.7 100.624 119.485 96.5634C119.393 96.5205 119.302 96.499 119.21 96.456C133.46 86.7889 142.723 71.0423 142.723 53.2764C142.723 23.8455 117.335 0 86 0C54.6651 0 29.277 23.8455 29.277 53.2764C29.277 71.0423 38.5402 86.7889 52.7896 96.4775C52.6981 96.5205 52.6066 96.542 52.5151 96.5849C42.2684 100.645 33.0738 106.467 25.1829 113.9C17.331 121.259 11.0708 129.977 6.74787 139.571C2.50101 148.964 0.210583 159.035 0.000571942 169.238C-0.00553276 169.468 0.0372846 169.696 0.126502 169.909C0.21572 170.123 0.349532 170.317 0.520054 170.482C0.690576 170.646 0.894358 170.776 1.11939 170.865C1.34443 170.954 1.58616 171 1.83035 171H15.5537C16.56 171 17.3606 170.248 17.3834 169.324C17.8409 152.74 24.9313 137.208 37.4652 125.436C50.4337 113.255 67.6565 106.553 86 106.553C104.343 106.553 121.566 113.255 134.535 125.436C147.069 137.208 154.159 152.74 154.617 169.324C154.639 170.27 155.44 171 156.446 171H170.17C170.414 171 170.656 170.954 170.881 170.865C171.106 170.776 171.309 170.646 171.48 170.482C171.65 170.317 171.784 170.123 171.873 169.909C171.963 169.696 172.006 169.468 171.999 169.238C171.771 158.97 169.506 148.981 165.252 139.55V139.55ZM86 90.2261C75.5017 90.2261 65.6209 86.3808 58.1874 79.399C50.754 72.4172 46.6598 63.1368 46.6598 53.2764C46.6598 43.416 50.754 34.1356 58.1874 27.1538C65.6209 20.172 75.5017 16.3266 86 16.3266C96.4983 16.3266 106.379 20.172 113.813 27.1538C121.246 34.1356 125.34 43.416 125.34 53.2764C125.34 63.1368 121.246 72.4172 113.813 79.399C106.379 86.3808 96.4983 90.2261 86 90.2261Z"
      fill="currentColor"
    />
  </svg>
);

const TaskIconOutlined = (props) => <Icon component={TaskSvg} {...props} />;
const StaffIconOutlined = (props) => <Icon component={StaffsSvg} {...props} />;

const projectNavTree = [
  {
    key: "Projects",
    path: `${APP_PREFIX_PATH}/dashboard/projects`,
    title: "sidenav.projects",
    icon: ProjectOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "project-list",
        path: `${APP_PREFIX_PATH}/dashboard/projects/list`,
        title: "sidenav.projects.list",
        icon: ProjectOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "my-tasks",
        path: `${APP_PREFIX_PATH}/dashboard/projects/my-tasks`,
        title: "sidenav.projects.myTask",
        icon: TaskIconOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const staffNavTree = [
  {
    key: "staffs",
    path: `${APP_PREFIX_PATH}/dashboard/staffs`,
    title: "sidenav.staffs",
    icon: UserOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "roles",
        path: `${APP_PREFIX_PATH}/dashboard/roles`,
        title: "sidenav.staffs.roles",
        icon: StaffIconOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "staff-list",
        path: `${APP_PREFIX_PATH}/dashboard/staffs/list`,
        title: "sidenav.staffs.list",
        icon: StaffIconOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "staff-new",
        path: `${APP_PREFIX_PATH}/dashboard/staffs/new`,
        title: "sidenav.staffs.new",
        icon: UserAddOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const departmentNavTree = [
  {
    key: "departments",
    path: `${APP_PREFIX_PATH}/dashboard/departments`,
    title: "sidenav.departments",
    icon: ProfileOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "deparment-list",
        path: `${APP_PREFIX_PATH}/dashboard/departments/list`,
        title: "sidenav.departments.list",
        icon: ProfileOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const communicationNavTree = [
  {
    key: "communications",
    path: `${APP_PREFIX_PATH}/dashboard/chat`,
    title: "sidenav.communications",
    icon: ProfileOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "message-list",
        path: `${APP_PREFIX_PATH}/dashboard/chat`,
        title: "sidenav.communcations.chat",
        icon: ProfileOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "meeting-list",
        path: `${APP_PREFIX_PATH}/dashboard/meetings/list`,
        title: "sidenav.communcations.meet",
        icon: ProfileOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];



const infoNavTree = [
  {
    key: "Info",
    path: `${APP_PREFIX_PATH}/dashboard/notices`,
    title: "sidenav.info",
    icon: ProfileOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "notice-list",
        path: `${APP_PREFIX_PATH}/dashboard/notices/board`,
        title: "sidenav.info.board",
        icon: ProfileOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "calendar",
        path: `${APP_PREFIX_PATH}/dashboard/notices/calendar`,
        title: "sidenav.info.calendar",
        icon: ProfileOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "my-leaves",
        path: `${APP_PREFIX_PATH}/dashboard/leaves/me`,
        title: "sidenav.info.my-leaves",
        icon: ProfileOutlined,
        breadcrumb: true,
        submenu: [],
      },

      {
        key: "leaves",
        path: `${APP_PREFIX_PATH}/dashboard/leaves/list`,
        title: "sidenav.info.leaves",
        icon: ProfileOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const filesNavTree = [
  {
    key: "File Manager",
    path: `${APP_PREFIX_PATH}/dashboard/file-manager`,
    title: "sidenav.file-manager",
    icon: ProfileOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "files-list",
        path: `${APP_PREFIX_PATH}/dashboard/file-manager`,
        title: "sidenav.file-manager.files",
        icon: ProfileOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [
  ...projectNavTree,
  ...communicationNavTree,
  ...filesNavTree,
  ...infoNavTree,
  ...departmentNavTree,
  ...staffNavTree,
];

export default navigationConfig;
