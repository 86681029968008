import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getStaffs } from "redux/actions/Staff";
import {
  getUserRooms,
  initiateChat,
  showLoading,
  updateChatWithNewMessage,
  updateChatWithUpdatedMessage,
  removeMessageFromChat,
} from "redux/actions/Chat";
import { AUTH_USER } from "redux/constants/Auth";

import { useMeetingState } from "../../../../providers/MeetingStateProvider";

import MeetingChat from "./chat-modal";

export function ChatControl(props) {
  const { chatRoomId, meetingId } = useMeetingState();

  const profileData = JSON.parse(localStorage.getItem(AUTH_USER));

  useEffect(() => {
    props.getUserRooms(profileData?.id, meetingId);
  }, []);

  useEffect(() => {
      if(props.io){
        props.io.emit("join-chatroom", chatRoomId);
      }
  }, [props.io, chatRoomId]);

  return <MeetingChat {...props} chatRoomId={chatRoomId} />;
}

const mapStateToProps = (data) => {
  const { loading, rooms } = data.chat;
  const { staffs } = data.staff;
  const { io } = data.socket;

  return { loading, rooms, io, staffs };
};
const mapDispatchToProps = {
  getUserRooms,
  updateChatWithNewMessage,
  initiateChat,
  updateChatWithUpdatedMessage,
  removeMessageFromChat,
  getStaffs,
  showLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ChatControl));
