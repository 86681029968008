// Copyright 2020-2021 OTI , Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useContext, useState, ReactNode } from "react";
import { MeetingMode } from "../constants/MeetingConstant";

// type Props = {
//   children: ReactNode;
// };

// interface MeetingStateValue {
//   meetingId: string;
//   localUserName: string;
//   theme: string;
//   region: string;
//   meetingMode: MeetingMode;
//   toggleTheme: () => void;
//   setAppMeetingInfo: (meetingId: string, name: string, region: string) => void;
//   setMeetingMode: (meetingMode: MeetingMode) => void;
// }

const MeetingStateContext = React.createContext(null);

export function useMeetingState() {
  const state = useContext(MeetingStateContext);

  if (!state) {
    throw new Error("useMeetingState must be used within MeetingStateProvider");
  }

  return state;
}

const query = new URLSearchParams(window.location.search);

export function MeetingStateProvider({ children }) {
  const [meetingId, setMeeting] = useState(query.get("meetingId") || "");
  const [title, setTitle] = useState("");
  const [chatRoomId, setChatRoomId] = useState();
  const [description, setDescription] = useState("");
  const [region, setRegion] = useState(query.get("region") || "");
  const [meetingMode, setMeetingMode] = useState(MeetingMode.Attendee);
  const [localUserName, setLocalName] = useState("");
  const [isHost, setIsHost] = useState(null);


  const [theme, setTheme] = useState(() => {
    const storedTheme = localStorage.getItem("theme");
    return storedTheme || "light";
  });

  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark");
      localStorage.setItem("theme", "dark");
    } else {
      setTheme("light");
      localStorage.setItem("theme", "light");
    }
  };

  const setAppMeetingInfo = (meetingId, name, region, title, description, chatRoomId, isHost) => {
    setRegion(region);
    setMeeting(meetingId);
    setLocalName(name);
    setTitle(title);
    setChatRoomId(chatRoomId)
    setDescription(description);
    setIsHost(isHost)
  };

  const providerValue = {
    meetingId,
    localUserName,
    theme,
    region,
    meetingMode,
    title,
    chatRoomId,
    description,
    isHost,
    toggleTheme,
    setAppMeetingInfo,
    setMeetingMode,
  };

  return (
    <MeetingStateContext.Provider value={providerValue}>
      {children}
    </MeetingStateContext.Provider>
  );
}
