import React from "react"
import {Avatar, List } from "antd";
import Flex from "components/shared-components/Flex";
import {
  MailOutlined,
  WarningOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

export default function NotificationItem({item, socket, ...props}){
  const getIcon = (icon) => {
    switch (icon) {
      case "mail":
        return <MailOutlined />;
      case "alert":
        return <WarningOutlined />;
      case "check":
        return <CheckCircleOutlined />;
      default:
        return <MailOutlined />;
    }
  };
    return (
        <List.Item className="list-clickable" onClick={()=>socket.emit("read-notification", item._id)}>
        <Flex alignItems="center">
          <div className="pr-3">
            {item.img ? (
              <Avatar src={`/img/avatars/${item.img}`} />
            ) : (
              <Avatar
                // className={`ant-avatar-${item.type}`}
                className={`ant-avatar`}
                icon={getIcon(item.icon)}
              />
            )}
          </div>
          <div className="mr-3">
            {/* <span className="font-weight-bold text-dark">{item.name} </span> */}
            <span className="text-gray-light text-dark">{item.message}</span>
          </div>
          {/* <small className="ml-auto">{item.time}</small> */}
        </Flex>
      </List.Item>
    )
}