import fetch from 'auth/FetchInterceptor'

const DepartmentService = {}

DepartmentService.addDepartment = function (data) {
	return fetch({
		url: '/departments',
		method: 'post',
		data: data
	})

}

DepartmentService.getDepartments = function (params) {
	return fetch({
	  url: '/departments',
	  method: 'get',
	  params
	})
  }



export default DepartmentService