import fetch from 'auth/FetchInterceptor'

const NotificationService = {}



NotificationService.getStaffNotifications = function (params) {
	return fetch({
	  url: '/notifications',
	  method: 'get',
	  params
	})
  }



export default NotificationService