import {
  AUTH_TOKEN,
  AUTH_USER,
  AUTHENTICATED,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNOUT_SUCCESS,
  SHOW_LOADING,
  VERIFY_CODE_SUCCESS,
  RESET_TO_INITIAL,
  RESET_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_SUCCESS,
} from "../constants/Auth";

const initState = {
  loading: false,
  message: "",
  messageType: null,
  showMessage: false,
  redirect: "",
  codeVerified: false,
  resetSuccess: false,
  forgotSuccess: false,
  token: localStorage.getItem(AUTH_TOKEN),
  user: JSON.parse(localStorage.getItem(AUTH_USER)),
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case AUTHENTICATED:
      return {
        ...state,
        loading: false,
        redirect: "/",
        token: action.token,
      };
    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        messageType: "error",
        showMessage: true,
        loading: false,
      };
    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: "",
        showMessage: false,
        messageType: null,
      };
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        token: null,
		user:null,
        redirect: "/",
        loading: false,
      };
    }
    case VERIFY_CODE_SUCCESS:
      return {
        ...state,
        message: action.message,
        messageType: action.messageType,
        codeVerified: action.messageType === "success",
        showMessage: true,
        loading: false,
      };

    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        message: action.message,
        messageType: action.messageType,
        forgotSuccess: action.messageType === "success",
        showMessage: true,
        loading: false,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        message: action.message,
        messageType: action.messageType,
        resetSuccess: action.messageType === "success",
        showMessage: true,
        loading: false,
      };

    case RESET_TO_INITIAL:
      const resetObj = action.fields.reduce((acc, field) => {
        acc[field] = initState[field];
        return acc;
      }, {});
      return {
        ...state,
        ...resetObj,
      };
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    default:
      return state;
  }
};

export default auth;
