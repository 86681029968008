import {
  SHOW_LOADING,
  ADD_NOTICE_SUCCESS,
  GET_NOTICES_SUCCESS,
  SHOW_ERROR_MESSAGE
} from "../constants/NoticeBoard";



const initState = {
  loading: false,
  notice: null,
  notices:[],
  message: "",
  messageType: null,
  showMessage: false,
};

const notice = (state = initState, action) => {
  switch (action.type) {
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SHOW_ERROR_MESSAGE:
      return {
        ...state,
        message: action.message,
        messageType: "error",
        showMessage: true,
        loading: false,
      };

    case ADD_NOTICE_SUCCESS:
      return {
        ...state,
        loading: false,
        showMessage: true,
        message: `Notice added`,
        messageType: "success",
        notice: action.notice,
        notices: [...state.notices, action.notice]
      };


	  case GET_NOTICES_SUCCESS:
		return {
			...state,
			loading: false,
			notices: action.notices
		}


    default:
      return state;
  }
};

export default notice;
