import {
ADD_NOTICE,
ADD_NOTICE_SUCCESS,
GET_NOTICES,
GET_NOTICES_SUCCESS,
SHOW_LOADING,
SHOW_ERROR_MESSAGE

} from '../constants/NoticeBoard';

export const addNotice = (notice) => {
  return {
    type: ADD_NOTICE,
    payload: notice
  }
};



export const addNoticeSuccess = (notice) => {
  return {
    type: ADD_NOTICE_SUCCESS,
    notice,
  }
};


export const getNotices = () => {
  return {
    type: GET_NOTICES
  }
};



export const getNoticesSuccess = (notices) => {
  return {
    type: GET_NOTICES_SUCCESS,
    notices,
  }
};



export const showErrorMessage = (message) => {
  return {
    type: SHOW_ERROR_MESSAGE,
    message
  }
};






export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};


