import React from "react";
import { Button, Row, Col } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className="text-center mb-5">
      <img className="img-fluid" src="/img/others/404.svg" alt="" />
      <h1 className="font-weight-bold mb-4 display-4">Page not found</h1>
      <p className="font-size-md mb-4">
        We've noticed you lost your way, no worries, we will help you to found
        the correct path.
      </p>
      <Link to="/app">
        <Button type="primary">Back to Home</Button>
      </Link>
    </div>
  );
};

export default NotFoundPage;
