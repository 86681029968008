import {
    CREATE_A_MEETING,
    CREATE_MEETING_SUCCESS,
    SET_MEETING,
    JOIN_A_MEETING,
    JOIN_MEETING_SUCCESS,
    GET_USER_MEETINGS,
    GET_USER_MEETINGS_SUCCESS,
    END_A_MEETING,
    END_MEETING_SUCCESS,
    SHOW_LOADING,
    GET_CALENDAR_MEETINGS_SUCCESS,
    GET_CALENDAR_MEETINGS

  } from "../constants/Meeting";
  
  export const createMeeting = (data) => {
    return {
      type: CREATE_A_MEETING,
      payload: data,
    };
  };
  
  export const createMeetingSuccess = (meeting) => {
    return {
      type: CREATE_MEETING_SUCCESS,
      meeting,
    };
  };


  export const setMeeting = (meeting) => {
    return {
      type: SET_MEETING,
      meeting,
    };
  };




  export const joinMeeting = (data) => {
    return {
      type: JOIN_A_MEETING,
      payload: data,
    };
  };
  
  export const joinMeetingSuccess = (joinInfo) => {
    return {
      type: JOIN_MEETING_SUCCESS,
      joinInfo,
    };
  };


  export const getUserMeetings = (attendeeId) => {
    return {
      type: GET_USER_MEETINGS, 
      payload: {attendeeId},

    };
  };
  
  export const getUserMeetingsSuccess = (meetings) => {
    return {
      type: GET_USER_MEETINGS_SUCCESS,
      meetings,
    };
  };


  export const endMeeting = (data) => {
    return {
      type: END_A_MEETING,
      payload: data,
    };
  };
  
  export const endMeetingSuccess = () => {
    return {
      type: END_MEETING_SUCCESS,
    };
  };


  export const showLoading = () => {
    return {
      type: SHOW_LOADING,
    };
  };




  export const getCalendarMeetings = () => {
    return {
      type: GET_CALENDAR_MEETINGS, 

    };
  };
  
  export const getCalendarMeetingsSuccess = (meetings) => {
    return {
      type: GET_CALENDAR_MEETINGS_SUCCESS,
      meetings,
    };
  };



  // export const showAuthMessage = (message) => {
  //   return {
  //     type: SHOW_AUTH_MESSAGE,
  //     message,
  //   };
  // };
  
  // export const hideAuthMessage = () => {
  //   return {
  //     type: HIDE_AUTH_MESSAGE,
  //   };
  // };