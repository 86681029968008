import React from 'react'
import { Switch, Route, } from "react-router-dom";
import MeetingViews from 'views/meeting-views';
import Loading from 'components/shared-components/Loading';
import { useThemeSwitcher } from "react-css-theme-switcher";

export const MeetingLayout = () => {
	const { status } = useThemeSwitcher();

  if (status === 'loading') {
    return <Loading cover="page" />;
  }

	return (
			<Switch>
				<Route path="" component={MeetingViews} />
			</Switch>
	)
}


export default MeetingLayout
