import {
	SHOW_LOADING,
	ADD_ROLE_SUCCESS,
	GET_ALL_PRIVILEGES_SUCCESS,
    GET_ALL_ROLES_SUCCESS
} from '../constants/Idm';

const initState = {
  loading: false,
  role: null,
  message: '',
  showMessage: false,
  roles: [],
  privileges: []

}

const department = (state = initState, action) => {
	switch (action.type) {
		case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}

		case ADD_ROLE_SUCCESS:
			return {
				...state,
				loading: false,
				role: action.role,
				roles: [...state.roles, action.roles]
			}

			case GET_ALL_ROLES_SUCCESS:
				return {
					...state,
					loading: false,
					roles: action.roles
				}

                case GET_ALL_PRIVILEGES_SUCCESS:
                    return {
                      ...state,
                      loading: false,
                      privileges: action.privileges,
                    };
		default:
			return state;
	}
}

export default department