import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  AUTH_TOKEN,
  AUTH_USER,
  SIGNIN,
  SIGNOUT,
  FORGOT_PASSWORD,
  VERIFY_CODE,
  RESET_PASSWORD,
} from "../constants/Auth";
import {
  showAuthMessage,
  authenticated,
  signOutSuccess,
  verifyCodeSuccess,
  resetPasswordSuccess,
  forgotPasswordSuccess
} from "../actions/Auth";

import authService from "services/AuthService";

export function* signIn() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    const { staffId, password } = payload;
    try {
      const user = yield call(authService.login, { staffId, password });
      if (user.error) {
        yield put(showAuthMessage(user.error.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.token);
        localStorage.setItem(AUTH_USER, JSON.stringify(user.staff));
        yield put(authenticated(user.token));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      const result = yield call(authService.logout);
      if (!result.error) {
        localStorage.removeItem(AUTH_TOKEN);
        yield put(signOutSuccess());
      } else {
        yield put(showAuthMessage(result.error.message));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* forgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, function* ({ payload }) {
    const { staffId } = payload;
    try {
      const result = yield call(authService.forgotPassword, { staffId });
      if (result.error) {
        yield put(forgotPasswordSuccess("error", result.error.message));
      } else {
        yield put(
			forgotPasswordSuccess(
            "success",
            "A password reset email has been sent to the email associated with your account"
          )
        );
      }
    } catch (err) {
      yield put(forgotPasswordSuccess("error", "An error occurred. Please try again"));
    }
  });
}

export function* verifyCode() {
  yield takeEvery(VERIFY_CODE, function* ({ payload }) {
    const { staffId, verificationCode } = payload;
    try {
      const result = yield call(authService.verifyCode, {
        staffId,
        verificationCode,
      });
      if (result.error) {
        yield put(verifyCodeSuccess("error", result.error.message));
      } else {
        yield put(verifyCodeSuccess("success", "Verification successful"));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* resetPassword() {
  yield takeEvery(RESET_PASSWORD, function* ({ payload }) {
    try {
      const result = yield call(authService.resetPassword, payload);
      if (result.error) {
        yield put(resetPasswordSuccess("error", result.error.message));
      } else {
        yield put(resetPasswordSuccess("success", "Password reset successful"));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(signIn),
    fork(signOut),
    fork(forgotPassword),
    fork(verifyCode),
    fork(resetPassword),
  ]);
}
