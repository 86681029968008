import fetch from "auth/FetchInterceptor";

const AuthService = {};

AuthService.login = function (data) {
  return fetch({
    url: "/auth/login",
    method: "post",
    data: data,
    headers: {
      "public-request": "true",
    }
  });
};


AuthService.logout = function () {
  return fetch({
    url: "/auth/logout",
    method: "post",
    data: {}
  });
};


AuthService.forgotPassword = function ({staffId}) {
  return fetch({
    url: "/auth/forgot-password",
    method: "post",
    data: {staffId},
    headers: {
      "public-request": "true",
    }
  });
};



AuthService.verifyCode = function ({staffId, verificationCode}) {
  return fetch({
    url: "/auth/verify-code",
    method: "post",
    data: {staffId, verificationCode},
    headers: {
      "public-request": "true",
    }
  });
};


AuthService.resetPassword = function ({staffId, verificationCode, password, confirmPassword}) {
  return fetch({
    url: "/auth/reset-password",
    method: "post",
    data: {staffId, verificationCode, password, confirmPassword},
    headers: {
      "public-request": "true",
    }
  });
};

export default AuthService;
