import React from "react";
import { Switch, Route } from "react-router-dom";
import { APP_NAME } from "configs/AppConfig";
import Flex from "components/shared-components/Flex";
import { useSelector } from "react-redux";
import ErrorViews from "../../views/error-views";

const ErrorLayout = () => {
  const theme = useSelector((state) => state.theme.currentTheme);
  return (
    <div className="auth-container">
      <div className={`h-100 ${theme === "light" ? "bg-white" : ""}`}>
        <div className="container-fluid d-flex flex-column justify-content-between h-100 px-md-4 pb-md-4 pt-md-1">
          <div>
            <img
              className="img-fluid"
              src={`/img/${theme === "light" ? "logo.png" : "logo-white.png"}`}
              alt=""
            />
          </div>
          <div className="container">
            <Switch>
              <Route path="" component={ErrorViews} />
            </Switch>
          </div>
          <Flex mobileFlex={false} justifyContent="between">
            <span>
              Copyright &copy; {`${new Date().getFullYear()}`}{" "}
              <span className="font-weight-semibold">{`${APP_NAME}`}</span>
            </span>
            <div>
              <a
                className="text-gray"
                href="/#"
                onClick={(e) => e.preventDefault()}
              >
                Term & Conditions
              </a>
              <span className="mx-2 text-muted"> | </span>
              <a
                className="text-gray"
                href="/#"
                onClick={(e) => e.preventDefault()}
              >
                Privacy & Policy
              </a>
            </div>
          </Flex>
        </div>
      </div>
    </div>
  );
};

export default ErrorLayout;
