import {
  SHOW_LOADING,
  CREATE_MEETING_SUCCESS,
  SET_MEETING,
  JOIN_MEETING_SUCCESS,
  GET_USER_MEETINGS_SUCCESS,
  END_MEETING_SUCCESS,
  GET_CALENDAR_MEETINGS_SUCCESS
} from "../constants/Meeting";

const initState = {
  messageType: null,
  loading: false,
  meeting: null,
  message: "",
  showMessage: false,
  meetings: [],
  calendarMeetings: [],
  joinInfo: null,
};

const meeting = (state = initState, action) => {
  switch (action.type) {
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case CREATE_MEETING_SUCCESS:
      return {
        ...state,
        loading: false,
        meeting: action.meeting,
        meetings: [...state.meetings, action.meeting],
      };

    case SET_MEETING:
      return {
        ...state,
        meeting: action.meeting,
      };

    case JOIN_MEETING_SUCCESS:
      return {
        ...state,
        loading: false,
        joinInfo: action.joinInfo,
      };

    case GET_USER_MEETINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        meetings: action.meetings,
        meeting:action.meetings[0]
      };

    case END_MEETING_SUCCESS:
      return {
        ...state,
        loading: false,
        meetings: action.meetings,
      };


      case GET_CALENDAR_MEETINGS_SUCCESS:
        return {
          ...state,
          loading: false,
          calendarMeetings: action.meetings,
        };

    default:
      return state;
  }
};

export default meeting;
