// Copyright 2021 OTI , Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React from 'react';
import { StyledItemMetricName, StyledItemMetricValue } from './Styled';

// export interface MetricItemProps {
//   metricName: string;
//   metricValues: string[];
// }

export const MetricItem= ({
  metricName,
  metricValues
}) => {
  const showMetricItem = metricValues[0] && metricValues[0] !== '';
  return (
    <>
      {showMetricItem && (
        <>
          <StyledItemMetricName>{metricName}</StyledItemMetricName>
          {metricValues.map(metricValue => {
            return <StyledItemMetricValue>{metricValue}</StyledItemMetricValue>;
          })}
        </>
      )}
    </>
  );
};

export default MetricItem;
