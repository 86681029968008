import fetch from 'auth/FetchInterceptor'

const staffService = {}

staffService.getStaffs = function (params) {
  return fetch({
    url: '/staffs',
    method: 'get',
    params
  })
}


staffService.getStaff = function (id, params) {
  return fetch({
    url: `/staffs/${id}`,
    method: 'get',
    headers: {
      "page-request": "true",
      },
    params
  })
}

staffService.addStaff = function (data) {
  return fetch({
    url: '/staffs',
    method: 'post',
    data: data
  })
}


staffService.updteStaff = function (id, data) {
  return fetch({
    url: `/staffs/${id}`,
    method: 'put',
    data: data
  })
}


staffService.changePassword = function (data) {
  return fetch({
    url: `/staffs/change-password`,
    method: 'put',
    data: data
  })
}
export default staffService