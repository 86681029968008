import {
  CONNECT_SOCKET_IO,
  DISCONNECT_SOCKET_IO,
} from "../constants/socket.io";
import { io } from "socket.io-client";




const initState = {
  io: null,
};


const socketIo = (state = initState, action) => {
  switch (action.type) {
    case CONNECT_SOCKET_IO:
      const socket = io(process.env.REACT_APP_SOCKET_ENDPOINT, {
        extraHeaders: {
          Authorization: `Bearer ${action.token}`,
        },
      });
      socket.once("connect", () => {
        socket.on("online", (userId) => {
          console.log(userId, "Is Online!"); // update online status
        });

        // USER IS OFFLINE
        socket.on("offline", (userId) => {
          console.log(userId, "Is Offline!"); // update offline status
        });
      });
      return {
        ...state,
        io: socket,
      };


      case DISCONNECT_SOCKET_IO:
        console.log("Disconnecting socket...");
        if (action.socket) action.socket.disconnect();
        return {
          ...state,
          io: null,
        };

    default:
      return state;
  }
};


export default socketIo;
