// Copyright 2020-2021 OTI , Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {Button} from "antd"
import {
  ControlBarButton,
  Phone,
  Modal,
  ModalBody,
  ModalHeader,
  ModalButton,
  ModalButtonGroup,
} from "amazon-chime-sdk-component-library-react";

import { StyledP } from "./Styled";
import { useMeetingState } from "../../../../providers/MeetingStateProvider";
import routes from "../../../../constants/routes";

const EndMeetingControl = (props) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const { meetingId, isHost } = useMeetingState();
  const history = useHistory();

  const leaveMeeting = async () => {
    history.push(routes.HOME);
  };

  const endMeetingForAll = async () => {
    if (meetingId) {
      props.endMeeting({ meetingId });
      history.push(routes.HOME);
    }
  };

  return (
    <>
      <ControlBarButton icon={<Phone />} onClick={toggleModal} label="Leave" />
      {showModal && (
        <Modal size="md" onClose={toggleModal} rootId="modal-root">
          <ModalHeader title="End Meeting" />
          <ModalBody>
            <StyledP>
              Leave meeting or you can end the meeting for all. The meeting
              cannot be used once it ends.
            </StyledP>
          </ModalBody>
          <ModalButtonGroup
            primaryButtons={
              isHost
                ? [
                    <ModalButton
                      onClick={endMeetingForAll}
                      variant="primary"
                      label="End meeting for all"
                      closesModal
                    />,
                    <ModalButton
                      onClick={leaveMeeting}
                      variant="primary"
                      label="Leave Meeting"
                      closesModal
                    />,
                    <ModalButton
                      variant="secondary"
                      label="Cancel"
                      closesModal
                    />,
                  ]
                : [
                    <ModalButton
                      onClick={leaveMeeting}
                      variant="primary"
                      label="Leave Meeting"
                      closesModal
                    />,
                    <ModalButton
                      variant="secondary"
                      label="Cancel"
                      closesModal
                    />,
                  ]
            }
          />
        </Modal>
      )}
    </>
  );
};

export default EndMeetingControl;
