// Copyright 2020-2021 OTI , Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React from "react";
import {
  ControlBar,
  AudioInputVFControl,
  VideoInputControl,
  ContentShareControl,
  AudioOutputControl,
  ControlBarButton,
  useUserActivityState,
  Dots,
  IconButton,
  Attendees,
} from "amazon-chime-sdk-component-library-react";

import EndMeetingControl from "../EndMeetingControl";
import VoiceFocusControl from "./VoiceFocus";
import ChatControl from "../chat";
import { useNavigation } from "../../../../providers/NavigationProvider";
import { StyledControls } from "./Styled";

const MeetingControls = (props) => {
  const { toggleNavbar, closeRoster, showRoster, toggleRoster } =
    useNavigation();
  const { isUserActive } = useUserActivityState();

  const handleToggle = () => {
    if (showRoster) {
      closeRoster();
    }

    toggleNavbar();
  };

  return (
    <StyledControls className="controls" active={!!isUserActive}>
      <ControlBar
        className="controls-menu"
        layout="undocked-horizontal"
        showLabels
      >
        <ControlBarButton
          className="mobile-toggle"
          icon={<Dots />}
          onClick={handleToggle}
          label="Menu"
        />
        <AudioInputVFControl
          voiceFocusOnLabel="Voice Focus Enabled"
          voiceFocusOffLabel="Enable Voice Focus"
        />
        <VideoInputControl />
        <ContentShareControl />
        <AudioOutputControl />
        <ControlBarButton
          // className="mobile-toggle"
          icon={<Attendees />}
          onClick={toggleRoster}
          label="Attendees"
        />
        <VoiceFocusControl />
        <ChatControl />
        <EndMeetingControl endMeeting={props.endMeeting} />
      </ControlBar>
    </StyledControls>
  );
};

export default MeetingControls;
