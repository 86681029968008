import {
  ADD_DEPARTMENT,
  ADD_DEPARTMENT_SUCCESS,
  SHOW_LOADING,
  GET_DEPARTMENTS,
  GET_DEPARTMENTS_SUCCESS
} from "../constants/Department";

export const addDepartment = (department) => {
  return {
    type: ADD_DEPARTMENT,
    payload: department,
  };
};

export const addDepartmentSuccess = (department) => {
  return {
    type: ADD_DEPARTMENT_SUCCESS,
    department,
  };
};

export const getDepartments = () => {
  return {
    type: GET_DEPARTMENTS,
  };
};

export const getDepartmentsSuccess = (departments) => {
  return {
    type: GET_DEPARTMENTS_SUCCESS,
    departments,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};
