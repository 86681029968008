// Copyright 2020-2021 OTI , Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import { SDK_LOG_LEVELS } from '../constants/MeetingConstant';

const urlParams = new URLSearchParams(window.location.search);
const queryLogLevel = urlParams.get('logLevel') || 'warn';
const logLevel = SDK_LOG_LEVELS[queryLogLevel] || SDK_LOG_LEVELS.warn;

const BASE_URL = [
  window.location.protocol,
  '//',
  window.location.host,
  window.location.pathname.replace(/\/*$/, '/'),
].join('');

const postLogConfig = {
  name: 'SDK_LOGS',
  batchSize: 85,
  intervalMs: 2000,
  // url: `${BASE_URL}logs`,
  logLevel: SDK_LOG_LEVELS.info,
};

const enableWebAudio = true;

const config = {
  // logLevel,
  // postLogConfig,
  enableWebAudio,
};

export default config;
