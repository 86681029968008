import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { OFFICE_PREFIX_PATH } from 'configs/AppConfig'

export const OfficeViews = () => {
  return (
    <Suspense fallback={<Loading cover="page"/>}>
      <Switch>
        <Route path={`${OFFICE_PREFIX_PATH}`} component={lazy(() => import(`./home`))} />
      </Switch>
    </Suspense>
  )
}

export default OfficeViews;

