import React from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { joinMeeting } from "redux/actions/Meeting";
import Utils from "utils";

import MeetingForm from "../container/MeetingForm2";

const Home = (props) => {
  const location = useLocation();
  const queryParams = Utils.getQueryParams(location);
  return (
    <div className="auth-container">
      <MeetingForm {...props} {...queryParams} />
    </div>
  );
};

const mapStateToProps = (data) => {
  const { loading, joinInfo, showMessage, message } = data.meeting;
  const { user } = data.auth;

  return { loading, joinInfo, showMessage, message, user };
};

const mapDispatchToProps = {
  joinMeeting,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
