import React, { useState } from "react";
import { connect } from "react-redux";
import Loading from "components/shared-components/Loading";
import MobileNav from "components/layout-components/MobileNav";
import HeaderNav from "components/layout-components/HeaderNav";
import OfficeViews from "views/office-views";
import { Layout, Grid, Drawer, Button, Tabs } from "antd";

import { NAV_TYPE_TOP } from "constants/ThemeConstant";

import utils from "utils";
import { useThemeSwitcher } from "react-css-theme-switcher";
const { TabPane } = Tabs;

const { useBreakpoint } = Grid;

export const OfficeLayout = ({ location, locale }) => {
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes("lg");

  const [visible, setVisible] = useState(true);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const getContainer = (id) => {
    return () => {
      if (!id || typeof document === "undefined") return null;
      const doc = document.getElementById(id);
      return doc;
    };
  };

  const { status } = useThemeSwitcher();

  if (status === "loading") {
    return <Loading cover="page" />;
  }

  return (
    <Layout>
      <HeaderNav
        isMobile={isMobile}
        useNavType={NAV_TYPE_TOP}
        handleNavPanel={showDrawer}
      />
      <Layout
        className="app-container"
        style={{
          backgroundImage: "url(/img/others/office-bg.jpeg)",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100vh",
        }}
      >
        <div className="app-content layout-top-nav" id="drawer-container">
          <OfficeViews />
        </div>
        <Drawer
          width={350}
          placement="right"
          onClose={onClose}
          closable={true}
          visible={visible}
          getContainer={getContainer("drawer-container")}
          style={{
            position: "absolute",
          }}
          mask={false}
          maskClosable={false}
        >
          <Tabs defaultActiveKey="1">
            <TabPane tab="Chat" key="1">
              Chat
            </TabPane>
            <TabPane tab="Live Wall" key="2">
              Live Wall
            </TabPane>
          </Tabs>
        </Drawer>
      </Layout>
      {isMobile && <MobileNav />}
    </Layout>
  );
};

const mapStateToProps = ({ theme }) => {
  const { locale } = theme;
  return { locale };
};

export default connect(mapStateToProps)(React.memo(OfficeLayout));
