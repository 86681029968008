import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {  GET_STAFF_NOTIFICATIONS } from "../constants/Notification";
import { getStaffNotificationsSuccess } from "../actions/Notification";

import notificationService from "services/NotificationService";




export function* getNotifications() {
	yield takeEvery(GET_STAFF_NOTIFICATIONS, function* ({params}) {
	  try {
		const notifications = yield call(notificationService.getStaffNotifications, params);
		if (notifications.error) {

		  // yield put(showAuthMessage(user.message));
		} else {
		  yield put(getStaffNotificationsSuccess(notifications.data.data, notifications.data.count));
		}
	  } catch (err) {
		console.log(err);
		// yield put(showAuthMessage(err));
	  }
	});
  }

export default function* rootSaga() {
  yield all([fork(getNotifications)]);
}
