import React, { useEffect } from "react";
import { Modal, Form, Input, Button, Select, Avatar, Upload } from "antd";
import { PlusOutlined, UserOutlined } from "@ant-design/icons";
import AssigneeAvatar from "components/shared-components/AssigneeAvatar";

import Flex from "components/shared-components/Flex"
const { Option } = Select;

const ChatGroupForm = ({
  visible,
  confirmLoading,
  handleOk,
  handleCancel,
  form,
  staffs,
  getStaffs,
  isUpdate,
  avatarUrl,
  handleGetBase64,
  onRemoveAvater
}) => {
  const memberTagRender2 = (props) => {
    const member = staffs.find((member) => member.id === props.value);
    return (
      member && <AssigneeAvatar showName={false} member={member} size={25} />
    );
  };

  useEffect(() => {
    getStaffs();
  }, []);

  return (
    <Modal
      title={isUpdate ? "Update Group" : "Create Group"}
      visible={visible}
      footer={null}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
    >
              <Flex
        alignItems="center"
        mobileFlex={false}
        className="text-center text-md-left"
      >
        <Avatar size={90} src={avatarUrl} icon={<UserOutlined />} />
        <div className="ml-3 mt-md-0 mt-3">
          <Upload showUploadList={false} beforeUpload={handleGetBase64}>
            <Button type="primary">
              {avatarUrl ? "Change Group Image" : "Add Group Image"}
            </Button>
          </Upload>
          <Button className="ml-2" onClick={onRemoveAvater}>
            Remove
          </Button>
        </div>
      </Flex>
      <Form
        layout="vertical"
        form={form}
        name="project-form"
        onFinish={handleOk}
      >
        <Form.Item style={{ maxHeight: "0px" }} name="id"></Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please input the group name",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[
            {
              required: true,
              message: "Please input the group description",
            },
          ]}
        >
          <Input.TextArea rows={2} />
        </Form.Item>
        <Form.Item
          label="Members"
          name="members"
          className="blockform-col col-12"
        >
          <Select
            filterOption={false}
            tagRender={memberTagRender2}
            mode="tags"
            removeIcon={null}
            placeholder="None"
            className="select"
          >
            {staffs.map((elm) => (
              <Option key={elm.id} value={elm.id}>
                <AssigneeAvatar member={elm} />
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            loading={confirmLoading}
            style={{ maxWidth: "120px", margin: "0 auto" }}
          >
            {!confirmLoading && <PlusOutlined />}
            <span>{isUpdate ? "Update" : "Create"}</span>
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChatGroupForm;
