export const ADD_PROJECT = 'ADD_PROJECT'; 
export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS'
export const SHOW_LOADING = 'SHOW_LOADING'
export const GET_PROJECTS = 'GET_PROJECTS'
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS'

export const GET_PROJECTS_MEMBERS = 'GET_PROJECTS_MEMBERS'
export const GET_PROJECTS_MEMBERS_SUCCESS = 'GET_PROJECTS_MEMBERS_SUCCESS'



export const ARCHIVE_PROJECT = 'ARCHIVE_PROJECT'; 
export const ARCHIVE_PROJECT_SUCCESS = 'ARCHIVE_PROJECT_SUCCESS'


export const UPDATE_PROJECT = 'UPDATE_PROJECT'; 
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS'








