// Copyright 2020-2021 OTI , Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, {useEffect } from "react";
import { connect } from "react-redux";
import { SocketListenerHoc } from "sockets/socket.listeners";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import {
  // lightTheme,
  MeetingProvider,
  NotificationProvider,
  darkTheme,
  GlobalStyles,
  VoiceFocusProvider,
} from "amazon-chime-sdk-component-library-react";

import {
  MeetingStateProvider,
  useMeetingState,
} from "../../providers/MeetingStateProvider";
import ErrorProvider from "../../providers/ErrorProvider";
import routes from "../../constants/routes";
import { NavigationProvider } from "../../providers/NavigationProvider";
import DeviceSetup from "./DeviceSetup";
import Home from "./Home2";
import Meeting from "./Meeting";

import { connectSocketIo, disconnectSocketIo } from "redux/actions/Socket.io";
import {
  updateChatWithNewMessage,
  updateRoomWithNewRoom,
  markChatRoomMessagesRead,
  updateChatWithUpdatedMessage,
  removeMessageFromChat,
  markChatRoomMessagesSent
} from "redux/actions/Chat";

import Notifications from "./container/Notifications";
import NoMeetingRedirect from "./container/NoMeetingRedirect";
import meetingConfig from "../../configs/meetingConfig";

const MeetingView = ({ token, io, connectSocketIo, disconnectSocketIo }) => {
  useEffect(() => {
    connectSocketIo(token);
    return () => {
      disconnectSocketIo(io);
    };
  }, []);
  return (
    <Router>
      <MeetingStateProvider>
        <Theme>
          <NotificationProvider>
            <Notifications />
            <ErrorProvider>
              <VoiceFocusProvider>
                <MeetingProvider {...meetingConfig}>
                  <NavigationProvider>
                    <Switch>
                      <Route exact path={routes.HOME} component={Home} />
                      <Route path={routes.DEVICE}>
                        <NoMeetingRedirect>
                          <DeviceSetup />
                        </NoMeetingRedirect>
                      </Route>
                      <Route path={routes.MEETING}>
                        <NoMeetingRedirect>
                          <MeetingModeSelector />
                        </NoMeetingRedirect>
                      </Route>
                    </Switch>
                  </NavigationProvider>
                </MeetingProvider>
              </VoiceFocusProvider>
            </ErrorProvider>
          </NotificationProvider>
        </Theme>
      </MeetingStateProvider>
    </Router>
  );
};

const mapStateToProps = ({ auth, socket }) => {
  const { token } = auth;
  const { io } = socket;

  return { token, io };
};

const mapDispatchToProps = {
  connectSocketIo,
  disconnectSocketIo,
  updateChatWithNewMessage,
  updateRoomWithNewRoom,
  markChatRoomMessagesRead,
  updateChatWithUpdatedMessage,
  removeMessageFromChat,
  markChatRoomMessagesSent
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(SocketListenerHoc(MeetingView)));

const Theme = ({ children }) => {
  const { theme } = useMeetingState();

  const customMeetingLightTheme = {
    name: "Light Theme",
    buttons: {
      primary: {
        shadow: "none",
        static: {
          bgd: "#327aff",
          border: "0.03125rem solid rgb(148, 125, 11)",
          text: "#ffffff",
          shadow: "none",
        },
        hover: {
          bgd: "rgb(148, 125, 11)",
          border: "0.03125rem solid #002f85",
          text: "#ffffff",
          shadow: "none",
        },
        focus: {
          bgd: "rgb(148, 125, 11)",
          border: "0.03125rem solid rgb(148, 125, 11)",
          text: "#ffffff",
          shadow: "0 0 0 0.25rem #fced9f",
        },
        active: {
          bgd: "rgb(148, 125, 11)",
          border: "0.03125rem solid #000000",
          text: "#ffffff",
          shadow: "0 0 0 0.25rem #fced9f",
        },
        selected: {
          bgd: "#327aff",
          border: "0.03125rem solid rgb(148, 125, 11)",
          text: "#ffffff",
          shadow: "none",
        },
        disabled: {
          bgd: "#ffffff",
          border: "0.03125rem solid #f0f1f2",
          text: "#989da5",
          shadow: "none",
        },
      },
      secondary: {
        shadow: "none",
        static: {
          bgd: "#ffffff",
          border: "0.03125rem solid #d4d5d8",
          text: "#3f4149",
          shadow: "none",
        },
        hover: {
          bgd: "#f0f1f2",
          border: "0.03125rem solid #d4d5d8",
          text: "#3f4149",
          shadow: "none",
        },
        focus: {
          bgd: "#f0f1f2",
          border: "0.03125rem solid rgb(148, 125, 11)",
          text: "#3f4149",
          shadow: "0 0 0 0.25rem #327aff",
        },
        active: {
          bgd: "#e4e9f2",
          border: "0.03125rem solid #989da5",
          text: "#3f4149",
          shadow: "0 0 0 0.25rem rgb(148, 125, 11)",
        },
        selected: {
          bgd: "#f0f1f2",
          border: "0.03125rem solid #d4d5d8",
          text: "#3f4149",
          shadow: "0 0 0 0.25rem rgb(148, 125, 11)",
        },
        disabled: {
          bgd: "#ffffff",
          border: "0.03125rem solid #f0f1f2",
          text: "#989da5",
          shadow: "none",
        },
      },
      icon: {
        shadow: "none",
        static: {
          bgd: "transparent",
          border: "0.03125rem  solid transparent",
          text: "#3f4149",
          shadow: "none",
        },
        hover: {
          bgd: "rgb(148, 125, 11)",
          border: "0.03125rem  solid transparent",
          text: "#ffffff",
          shadow: "none",
        },
        focus: {
          bgd: "transparent",
          border: "0.03125rem  solid rgb(148, 125, 11)",
          text: "#3f4149",
          shadow: "0 0 0 0.25rem #fced9f",
        },
        active: {
          bgd: "rgb(148, 125, 11)",
          border: "0.03125rem  solid rgb(148, 125, 11)",
          text: "#ffffff",
          shadow: "0 0 0 0.25rem #fced9f",
        },
        selected: {
          bgd: "#327aff",
          border: "0.03125rem  solid rgb(148, 125, 11)",
          text: "#ffffff",
          shadow: "none",
        },
        disabled: {
          bgd: "transparent",
          border: "none",
          text: "#989da5",
          shadow: "none",
        },
      },
    },
    colors: {
      primary: {
        lightest: "red",
        lighter: "red",
        light: "red",
        main: "red",
        dark: "red",
        darker: "red",
        darkest: "red",
      },
      secondary: {
        light: "#ff8e74",
        main: "#ff7654",
        dark: "#e86c4d",
      },
      error: {
        lightest: "#FCF7F6",
        lighter: "#F5DDD5",
        light: "#FF927C",
        primary: "#C52000",
        dark: "#9E3319",
        darker: "#89301A",
        darkest: "#791800",
      },
      success: {
        lightest: "#EBF1EA",
        lighter: "#CEE0C8",
        light: "#50CD49",
        primary: "#067000",
        dark: "#305D1D",
        darker: "#2C511D",
        darkest: "#184206",
      },
      info: {
        lightest: "#DADFE7",
        lighter: "#C4CCD9",
        light: "#418AFD",
        primary: "#2555A0",
        dark: "#264A82",
        darker: "#243F6B",
        darkest: "#123366",
      },
      warning: {
        lightest: "#FAF8EA",
        lighter: "#F7E79E",
        light: "#F9DC60",
        primary: "#F9CC09",
        dark: "#665A2A",
        darker: "#584E26",
        darkest: "#534201",
      },
      greys: {
        black: "#000000",
        grey100: "#1b1c20",
        grey90: "#2e2f34",
        grey80: "#3f4149",
        grey70: "#50545e",
        grey60: "#616672",
        grey50: "#7d818b",
        grey40: "#989da5",
        grey30: "#d4d5d8",
        grey20: "#e4e9f2",
        grey10: "#f0f1f2",
        white: "#ffffff",
      },
    },
    global: {
      bgd: "#ffffff",
      text: "#3f4149",
      fontSize: "16px",
    },
    links: {
      fontColor: "#075fff",
      fontColorHover: "rgb(148, 125, 11)",
      fontColorActive: "rgb(148, 125, 11)",
      fontColorVisited: "#002f85",
    },
    shadows: {
      none: "none",
      small: "0 0.09375rem 0.0625rem 0 rgba(27, 28, 32, 0.15)",
      medium: "0 0.5rem 0.85rem 0 rgba(0, 0, 0, 0.15)",
      large: "0 0.75rem 1.875rem 0 rgba(0, 0, 0, 0.15)",
    },
    inputs: {
      bgd: "#ffffff",
      border: "0.03125rem solid #d4d5d8",
      borderRadius: "0.25rem",
      fontColor: "#3f4149",
      placeholder: "#989da5",
      shadow: "0 0.0625rem 0.0625rem 0 rgba(0, 0, 0, 0.1)",
      clearBg: "#7d818b",
      focus: {
        bgd: "#ffffff",
        border: "solid 0.03125rem #5d96ff",
        shadow: "0 0 0 0.125rem #fced9f",
      },
      error: {
        border: "0.03125rem solid #9E3319",
        fontColor: "#C52000",
        shadow: "0 0 0 0.125rem #FF927C",
      },
      checked: {
        bgd: "#075fff",
        border: "solid 0.03125rem #075fff",
        fontColor: "#ffffff",
        shadow: "0 0.03125rem 0.03125rem 0 rgba(0, 77, 219, 0.7)",
      },
    },
    modal: {
      bgd: "#ffffff",
      text: "#3f4149",
      wrapperBgd: "rgba(125, 129, 139, 0.9)",
      titleSize: "1.5625rem",
      titleWeight: "normal",
      shadow: "0 0.75rem 1.875rem 0 rgba(0, 0, 0, 0.15)",
      border: "#d4d5d8",
    },
    popOver: {
      menuBgd: "rgba(240, 241, 242, 0.85)",
      menuBorder: "0.5px solid #e4e9f2",
      shadow: "0 0.75rem 1.875rem 0 rgba(0, 0, 0, 0.15)",
      itemBgd: "transparent",
      itemText: "#50545e",
      titleText: "#616672",
      active: {
        itemBgd: "rgb(148, 125, 11)",
        itemText: "#ffffff",
      },
      disabled: "#989da5",
      separator: "rgba(152, 157, 165, 0.3)",
    },
    notification: {
      shadow: "0 0.75rem 1.875rem 0 rgba(0, 0, 0, 0.15)",
      error: {
        text: "#FCF7F6",
        closeButton: {
          text: "#F5DDD5",
          hover: {
            bgd: "#F5DDD5",
            text: "#9E3319",
          },
          active: {
            bgd: "#FCF7F6",
            text: "#89301A",
          },
        },
      },
      success: {
        text: "#EBF1EA",
        closeButton: {
          text: "#CEE0C8",
          hover: {
            bgd: "#CEE0C8",
            text: "#305D1D",
          },
          active: {
            bgd: "#EBF1EA",
            text: "#2C511D",
          },
        },
      },
      info: {
        text: "#DADFE7",
        closeButton: {
          text: "#C4CCD9",
          hover: {
            bgd: "#C4CCD9",
            text: "#264A82",
          },
          active: {
            bgd: "#DADFE7",
            text: "#243F6B",
          },
        },
      },
      warning: {
        text: "#584E26",
        closeButton: {
          text: "#665A2A",
          hover: {
            bgd: "#665A2A",
            text: "#ffffff",
          },
          active: {
            bgd: "#584E26",
            text: "#ffffff",
          },
        },
      },
    },
    controlBar: {
      text: "#50545e",
      shadow: "0 0.75rem 1.875rem 0 rgba(0, 0, 0, 0.15)",
      bgd: "#ffffff",
      border: "0.03125rem solid #e4e9f2",
      opacity: 1,
      selected: {
        text: "#ffffff",
        bgd: "#327aff",
      },
    },
    roster: {
      title: "#1b1c20",
      primaryText: "#3f4149",
      secondaryText: "#7d818b",
      headerBorder: "#989da5",
      containerBorder: "#d4d5d8",
      bgd: "#f0f1f2",
      fgd: "#ffffff",
      shadow: "0 0.75rem 1.875rem 0 rgba(0, 0, 0, 0.15)",
      maxWidth: "18.5rem",
    },
    navbar: {
      text: "#3f4149",
      bgd: "#f0f1f2",
      headerBorder: "#989da5",
      wrapperBgd: "rgba(125, 129, 139, 0.9)",
    },
    videoGrid: {
      bgd: "#ffffff",
    },
    chatBubble: {
      incoming: {
        bgd: "#ffffff",
        fontColor: "#616672",
        linkColor: "#075fff",
        linkColorHover: "rgb(148, 125, 11)",
        linkColorActive: "rgb(148, 125, 11)",
        linkColorVisited: "#002f85",
      },
      outgoing: {
        bgd: "#075fff",
        fontColor: "#f0f1f2",
        linkColor: "#ffffff",
        linkColorHover: "#f0f1f2",
        linkColorActive: "#e4e9f2",
        linkColorVisited: "#d4d5d8",
      },
      container: {
        fontColor: "#50545e",
        bgd: "#f0f1f2",
      },
    },
    channelList: {
      bgd: "#ffffff",
      fontColor: "#50545e",
      border: "1px solid transparent",
      active: {
        bgd: "rgb(148, 125, 11)",
        fontColor: "#ffffff",
      },
      hover: {
        bgd: "#f0f1f2",
      },
      focus: {
        border: "1px solid rgb(148, 125, 11)",
        selectedBorder: "1px solid #f0f1f2",
      },
      selected: {
        bgd: "#327aff",
        fontColor: "#ffffff",
      },
      iconButton: {
        activeBgd: "#ffffff",
      },
    },
    chatDateHeader: {
      bgd: "#616672",
      fontColor: "#ffffff",
    },
    messageAttachment: {
      size: {
        fontColor: "#989da5",
        bgd: "#ffffff",
        letterSpacing: "-0.07px",
        lineHight: "16px",
        fontSize: "10.4px",
      },
      icon: {
        bgd: "#f0f1f2",
        color: "#3f4149",
      },
      name: {
        fontColor: "#3f4149",
      },
      content: {
        letterSpacing: "-0.09px",
        bgd: "#ffffff",
        fontColor: "#3f4149",
      },
    },
    breakpoints: ["20rem", "35.5rem", "48rem", "64rem", "90rem"],
    mediaQueries: {
      min: {
        xs: "@media screen and (min-width: 20rem)",
        sm: "@media screen and (min-width: 35.5rem)",
        md: "@media screen and (min-width: 48rem)",
        lg: "@media screen and (min-width: 64rem)",
        xl: "@media screen and (min-width: 90rem)",
      },
      max: {
        xs: "@media screen and (max-width: 20rem)",
        sm: "@media screen and (max-width: 35.5rem)",
        md: "@media screen and (max-width: 48rem)",
        lg: "@media screen and (max-width: 64rem)",
        xl: "@media screen and (max-width: 90rem)",
      },
    },
    fonts: {
      body: "'Ember', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;",
      monospace: "Menlo, monospace",
    },
    fontSizes: {
      baseFontSize: "16px",
      fontWeight: "normal",
      h1: {
        fontSize: "5.3rem",
        fontWeight: "normal",
        lineHeight: "5.625rem",
        mobile: {
          fontSize: "3.8125rem",
          fontWeight: "normal",
          lineHeight: "5.625rem",
        },
      },
      h2: {
        fontSize: "3.925rem",
        fontWeight: "normal",
        lineHeight: "3.75rem",
        mobile: {
          fontSize: "3.05rem",
          fontWeight: "normal",
          lineHeight: "4.5rem",
        },
      },
      h3: {
        fontSize: "2.44125rem",
        fontWeight: "normal",
        lineHeight: "3.75rem",
        mobile: {
          fontSize: "2.90625rem",
          fontWeight: "normal",
          lineHeight: "3rem",
        },
      },
      h4: {
        fontSize: "1.953125rem",
        fontWeight: "normal",
        lineHeight: "3.75rem",
        mobile: {
          fontSize: "2.15rem",
          fontWeight: "normal",
          lineHeight: "3rem",
        },
      },
      h5: {
        fontSize: "1.5625rem",
        fontWeight: "normal",
        lineHeight: "3rem",
        mobile: {
          fontSize: "1.59375rem",
          fontWeight: "normal",
          lineHeight: "1.875rem",
        },
      },
      h6: {
        fontSize: "1.25rem",
        fontWeight: "normal",
        lineHeight: "1.875rem",
        mobile: {
          fontSize: "1.18125rem",
          fontWeight: "normal",
          lineHeight: "1.5rem",
        },
      },
      text: {
        fontSize: "0.875rem",
        lineHeight: "1.43",
      },
      label: {
        fontSize: "0.875rem",
        lineHeight: "1.43",
      },
      small: {
        fontSize: "0.75rem",
        lineHeight: "1.43",
      },
      footnote: {
        fontSize: "0.65rem",
        lineHeight: "1rem",
      },
    },
    radii: {
      default: "0.25rem",
      circle: "50%",
    },
    zIndex: {
      navigation: 10,
      controlBar: 15,
      modal: 20,
      popOver: 30,
      notificationGroup: 40,
    },
    modalSizes: {
      md: {
        width: "35rem",
        height: "94vh",
      },
      lg: {
        width: "50rem",
        height: "94vh",
      },
      fullscreen: {
        width: "98vw",
        height: "96vh",
      },
    },
    iconButtonSizes: {
      sm: "1.5rem",
      md: "2.5rem",
      lg: "4rem",
    },
  };

  return (
    <ThemeProvider
      theme={theme === "light" ? customMeetingLightTheme : darkTheme}
    >
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};

const MeetingModeSelector = () => {
  const { meetingMode } = useMeetingState();

  return <Meeting mode={meetingMode} />;
};
