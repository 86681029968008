import {
  SIGNIN,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SHOW_LOADING,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  VERIFY_CODE,
  VERIFY_CODE_SUCCESS,
  RESET_TO_INITIAL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD
} from "../constants/Auth";

export const signIn = (user) => {
  return {
    type: SIGNIN,
    payload: user,
  };
};

export const authenticated = (token) => {
  return {
    type: AUTHENTICATED,
    token,
  };
};

export const signOut = () => {
  return {
    type: SIGNOUT,
  };
};

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message,
  };
};

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

export const forgotPassword = (payload) => {
  return {
    type: FORGOT_PASSWORD,
    payload,
  };
};

export const forgotPasswordSuccess = (messageType, message) => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    messageType,
    message
  };
};



export const verifyCode = (payload) => {
  return {
    type: VERIFY_CODE,
    payload,
  };
};

export const verifyCodeSuccess = (messageType, message) => {
  return {
    type: VERIFY_CODE_SUCCESS,
    messageType,
    message
  };
};


export const resetToInitialState = (fields) => {
  return {
    type: RESET_TO_INITIAL,
    fields
  };
};


export const resetPassword = (payload) => {
  return {
    type: RESET_PASSWORD,
    payload,
  };
};

export const resetPasswordSuccess = (messageType, message) => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    messageType,
    message
  };
};


