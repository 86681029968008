export const GET_USER_ROOMS = "GET_USER_ROOMS";
export const GET_USER_ROOMS_SUCCESS = "GET_USER_ROOMS_SUCCESS";
export const GET_ROOM_CHATS = "GET_ROOM_CHATS";
export const GET_ROOM_CHATS_SUCCESS = "GET_ROOM_CHATS_SUCCESS";
export const SHOW_LOADING = "SHOW_LOADING";
export const INITIATE_CHAT = "INITIATE_CHAT";
export const INITIATE_CHAT_SUCCESS = "INITIATE_CHAT_SUCCESS";
export const NEW_MESSAGE_RECEIVED = "NEW_MESSAGE_RECEIVED";
export const JOINED_NEW_ROOM = "JOINED_NEW_ROOM";
export const MARK_CHATROOM_MESSAGES_READ = "MARK_CHATROOM_MESSAGES_READ";
export const MARK_CHATROOM_MESSAGES_SENT = "MARK_CHATROOM_MESSAGES_SENT";
export const UPDATED_MESSAGE_RECEIVED = "UPDATED_MESSAGE_RECEIVED"
export const REMOVE_MESSAGE_FROM_CHAT = "REMOVE_MESSAGE_FROM_CHAT"

