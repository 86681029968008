import {
  SHOW_LOADING,
  ADD_PROJECT_TASK_SUCCESS,
  GET_PROJECTS_TASKS_SUCCESS,
  GET_STAFF_TASKS_SUCCESS,
  UPDATE_PROJECT_TASK_SUCCESS,
  UPDATE_PROJECT_BOARD_SUCCESS,
  ADD_PROJECT_BOARD_SUCCESS,
  AARCHIVE_BOARD_SUCCESS,
  ARCHIVE_TASK_SUCCESS,
  REVIEW_TASK_SUCCESS,
  ADD_TASK_COMMENT_SUCCESS,
  UPDATE_ORDERED,
  UPDATE_MODAL,
  UPDATE_MODAL_MODE,
  UPDATE_CURRENT_LIST_ID,
  UPDATE_CARD_DATA,
  UPDATE_COLUMN,
} from "../constants/Task";

const initState = {
  loading: false,
  message: "",
  showMessage: false,
  task: null,
  tasks: [],
  staffTasks: { upComingTask: [], dueTask: [], completedTask: [] },
  columns: {},
  boardsDetails: {},
  ordered: Object.keys({}),
  modal: false,
  modalMode: "",
  currentListId: "",
  currentList: null,
  cardData: null,
};

const task = (state = initState, action) => {
  switch (action.type) {
    case SHOW_LOADING:
      return {
        ...state,
        loading: true,
      };

    case ADD_PROJECT_TASK_SUCCESS:
      const tasks = [...state.tasks, action.task];
      const newColumns = Object.assign({}, state.columns);
      newColumns[`${action.task.board.id}-${action.task.board.name}`] = [
        ...newColumns[`${action.task.board.id}-${action.task.board.name}`],
        action.task,
      ];
      return {
        ...state,
        loading: false,
        modal: false,
        task: action.task,
        tasks: tasks,
        columns: newColumns,
        ordered: Object.keys(newColumns),
      };

    case GET_PROJECTS_TASKS_SUCCESS:
      let columns2 = action.tasks.reduce((acc, value) => {
        acc[`${value.id}-${value.name}`] = value.tasks;
        return acc;
      }, {});

      let boardsDetails = action.tasks.reduce((acc, value) => {
        delete value.tasks;
        acc[`${value.id}-${value.name}`] = value;
        return acc;
      }, {});
      return {
        ...state,
        loading: false,
        tasks: action.tasks,
        columns: columns2,
        boardsDetails: boardsDetails,
        ordered: Object.keys(columns2),
      };

    case GET_STAFF_TASKS_SUCCESS:
      return {
        ...state,
        loading: false,
        staffTasks: action.tasks,
      };

    case UPDATE_PROJECT_TASK_SUCCESS:
      // Clone columns
      let newColumnsUpdate = Object.assign({}, state.columns);
      // Get columns task
      let columnTasks =
        newColumnsUpdate[`${action.task.board.id}-${action.task.board.name}`];
      const columnTaskIndex = columnTasks.findIndex(
        (task) => task.id === action.task.id
      );

      if (columnTaskIndex !== -1) {
        columnTasks[columnTaskIndex] = action.task;
      }

      // Update

      newColumnsUpdate[`${action.task.board.id}-${action.task.board.name}`] =
        columnTasks;
      return {
        ...state,
        loading: false,
        modal: false,
        task: action.task,
        columns: newColumnsUpdate,
        ordered: Object.keys(newColumnsUpdate),
      };

      case ADD_TASK_COMMENT_SUCCESS:
        // Clone columns
        let newColumnsUpdateForComment = Object.assign({}, state.columns);
        // Get columns task
        let columnTasksForComment =
        newColumnsUpdateForComment[`${action.task.board.id}-${action.task.board.name}`];
        let columnTaskIndexForComment = columnTasksForComment.findIndex(
          (task) => task.id === action.task.id
        );
  
        if (columnTaskIndexForComment !== -1) {
          columnTasksForComment[columnTaskIndexForComment] = action.task;
        }
  
        // Update
  
        newColumnsUpdateForComment[`${action.task.board.id}-${action.task.board.name}`] =
        columnTasksForComment;
        return {
          ...state,
          loading: false,
          task: action.task,
          columns: newColumnsUpdateForComment,
          ordered: Object.keys(newColumnsUpdateForComment),
        };

    case UPDATE_PROJECT_BOARD_SUCCESS:
      let newColumnsUpdate2 = state.columns;
      let ordered = state.ordered;

      const oldTitleIndex = ordered.findIndex((title) =>
        title.startsWith(action.board.id)
      );
      let oldTitle = ordered[oldTitleIndex];
      const newTitle = `${action.board.id}-${action.board.name}`;
      if (newTitle !== oldTitle) {
        newColumnsUpdate2 = Object.assign({}, newColumnsUpdate2, {
          [newTitle]: newColumnsUpdate2[oldTitle],
        });
        delete newColumnsUpdate2[oldTitle];
        ordered[oldTitleIndex] = newTitle;
      }
      return {
        ...state,
        loading: false,
        modal: false,
        columns: newColumnsUpdate2,
        ordered,
      };

    case ADD_PROJECT_BOARD_SUCCESS:
      const boardTitle = `${action.board.id}-${action.board.name}`;

      const newColumnsUpdate3 = Object.assign({}, state.columns, {
        [boardTitle]: [],
      });

      const ordered3 = state.ordered;
      ordered3.push(boardTitle);

      return {
        ...state,
        loading: false,
        modal: false,
        columns: newColumnsUpdate3,
        ordered: ordered3,
      };

    case AARCHIVE_BOARD_SUCCESS:
      const archivedBoardTitle = `${action.board.id}-${action.board.name}`;
      const newColumnsUpdate4 = Object.assign({}, state.columns);
      delete newColumnsUpdate4[archivedBoardTitle];
      return {
        ...state,
        loading: false,
        columns: newColumnsUpdate4,
        ordered: Object.keys(newColumnsUpdate4),
      };

    case ARCHIVE_TASK_SUCCESS:
      const tasks2 = state.tasks.filter((task) => task.id !== action.task.id);
      const newColumns2 = Object.assign({}, state.columns);
      const cardTitle3 = `${action.task.board.id}-${action.task.board.name}`;
      const columnTasks2 = [...newColumns2[cardTitle3]];
      const newColumnTasks = columnTasks2.filter(
        (task) => task.id !== action.task.id
      );
      newColumns2[cardTitle3] = newColumnTasks;
      return {
        ...state,
        loading: false,
        task: action.task,
        tasks: tasks2,
        columns: newColumns2,
        currentList: null,
        model: false,
      };

    case REVIEW_TASK_SUCCESS:
      window.location.reload(false);
      return {
        ...state,
      };

    case UPDATE_ORDERED:
      return {
        ...state,
        ordered: action.payload,
      };
    case UPDATE_COLUMN:
      return {
        ...state,
        columns: action.payload,
      };
    case UPDATE_MODAL:
      return {
        ...state,
        modal: action.payload,
      };
    case UPDATE_MODAL_MODE:
      return {
        ...state,
        modalMode: action.payload,
      };
    case UPDATE_CURRENT_LIST_ID:
      return {
        ...state,
        currentListId: action.payload,
      };
    case UPDATE_CARD_DATA:
      return {
        ...state,
        cardData: action.payload,
      };

    default:
      return state;
  }
};

export default task;
