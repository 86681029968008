export const ADD_STAFF = 'ADD_STAFF'; 
export const ADD_STAFF_SUCCESS = 'ADD_STAFF_SUCCESS'
export const GET_STAFFS = 'GET_STAFFS'
export const GET_STAFF = 'GET_STAFF'
export const GET_STAFF_SUCCESS = 'GET_STAFF_SUCCESS'
export const GET_STAFFS_SUCCESS = 'GET_STAFFS_SUCCESS'
export const SHOW_LOADING = 'SHOW_LOADING'
export const SHOW_ERROR_MESSAGE = "SHOW_ERROR_MESSAGE"
export const RESET_TO_INITIAL = "RESET_TO_INITIAL"

export const UPDATE_STAFF = 'UPDATE_STAFF'; 
export const UPDATE_STAFF_SUCCESS = 'UPDATE_STAFF_SUCCESS'


export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'; 
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'



