// Copyright 2020-2021 OTI , Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React from 'react';

import {
  Flex,
  Heading,
  PrimaryButton,
  useMeetingManager
} from 'amazon-chime-sdk-component-library-react';

import { useMeetingState } from '../../../../providers/MeetingStateProvider';
import { StyledList } from './Styled';

const MeetingDetails = () => {
  const { meetingId, title, description, toggleTheme, theme } = useMeetingState();
  const manager = useMeetingManager();

  return (
    <Flex container layout="fill-space-centered">
      <Flex mb="2rem" mr={{ md: '2rem' }} px="1rem">
        <Heading level={4} tag="h1" mb={2}>
          Meeting information
        </Heading>
        <StyledList>
          <div>
            <dt>Meeting ID</dt>
            <dd>{meetingId}</dd>
          </div>
          <div>
            <dt>Meeting Title</dt>
            <dd>{title}</dd>
          </div>
          <div>
            <dt>Meeting Description</dt>
            <dd>{description}</dd>
          </div>
          <div>
            <dt>Hosted region</dt>
            <dd>{manager.meetingRegion}</dd>
          </div>
        </StyledList>
        {/* <PrimaryButton
          mt={4}
          label={theme === 'light' ? 'Dark mode' : 'Light mode'}
          onClick={toggleTheme}
        ></PrimaryButton> */}
      </Flex>
    </Flex>
  );
};

export default MeetingDetails;
