import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { ERROR_PREFIX_PATH } from 'configs/AppConfig'

export const ErrorViews = () => {
  return (
    <Suspense fallback={<Loading cover="page"/>}>
      <Switch>
        <Route path={`${ERROR_PREFIX_PATH}/404`} component={lazy(() => import(`./notfound`))} />
        <Route path={`${ERROR_PREFIX_PATH}/500`} component={lazy(() => import(`./servererror`))} />
        <Redirect  from={`${ERROR_PREFIX_PATH}`} to={`${ERROR_PREFIX_PATH}/404`} />
      </Switch>
    </Suspense>
  )
}

export default ErrorViews;

