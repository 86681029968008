import React, { useEffect } from "react";
import { Modal, Form, Input, Button, Select, Avatar, Upload } from "antd";
import { SendOutlined, FileOutlined } from "@ant-design/icons";
import { AssigneeAvatar2 } from "../project/tasks/utils";
import Flex from "components/shared-components/Flex";
const { Option } = Select;

const AttachmentForm = ({
  visible,
  confirmLoading,
  handleOk,
  handleCancel,
  form,
  attachment
}) => {

    const getAttachmentType = (attachment) => {
        switch (attachment.type) {
          case "image":
            return <img src={attachment.url} style={{ width: "100%" }} alt={attachment.name} />;
          case "file":
            return (
              <Flex alignItems="center" className="msg-file">
                <FileOutlined className="font-size-md" />
                <span className="ml-2 font-weight-semibold text-link pointer">
                  <u>{attachment.name}</u>
                </span>
              </Flex>
            );
          default:
            return null;
        }
      };
  return (
    <Modal
      title={"Send Attachment"}
      visible={visible}
      footer={null}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
    >
      <div style={{ width: "100%" }}>
          {attachment && getAttachmentType(attachment)}
      </div>

      <Form
        layout="vertical"
        form={form}
        name="attachment-form"
        onFinish={handleOk}
        style={{marginTop:"15px"}}
      >
        <Form.Item
          name="caption"
          
        >
          <Input placeholder="Add a Caption" />
        </Form.Item>

        <Form.Item>
          <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", marginTop: "10px" }}>
          <div>
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={confirmLoading}
              style={{ maxWidth: "120px", margin: "0 auto" }}
            >
              {!confirmLoading && <SendOutlined />}
              <span>Send</span>
            </Button>
          </div>
        </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AttachmentForm;
