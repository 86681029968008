import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	ADD_STAFF,
	UPDATE_STAFF,
	GET_STAFFS,
	GET_STAFF,
	CHANGE_PASSWORD
} from '../constants/Staff';
import {
	addStaffSuccess,
	updateStaffSuccess,
	getStaffSuccess,
	getStaffsSuccess,
	showErrorMessage,
	changePasswordSuccess
} from "../actions/Staff";

import staffService from 'services/StaffService'


export function* addStaff() {
  yield takeEvery(ADD_STAFF, function* ({payload}) {
		try {
			const staff = yield call(staffService.addStaff, payload);
			if (staff.error) {
				yield put(showErrorMessage(staff.error.message));
			} else {
				yield put(addStaffSuccess(staff.data));
			}
		} catch (err) {
			console.log(err)
			// yield put(showAuthMessage(err));
		}
	});
}



export function* getStaffs() {
	yield takeEvery(GET_STAFFS, function* () {
	  try {
		const staffs = yield call(staffService.getStaffs);
		if (staffs.error) {
		  // yield put(showAuthMessage(user.message));
		} else {
		  yield put(getStaffsSuccess(staffs.data));
		}
	  } catch (err) {
		console.log(err);
		// yield put(showAuthMessage(err));
	  }
	});
  }



  export function* getStaff() {
	yield takeEvery(GET_STAFF, function* ({id}) {
	  try {
		const staff = yield call(staffService.getStaff, id);
		if (staff.error) {
		  // yield put(showAuthMessage(user.message));
		} else {
		  yield put(getStaffSuccess(staff.data));
		}
	  } catch (err) {
		console.log(err);
		// yield put(showAuthMessage(err));
	  }
	});
  }



  export function* updateStaff() {
	yield takeEvery(UPDATE_STAFF, function* ({payload}) {
		  try {
			  const staff = yield call(staffService.updteStaff, payload.id, payload.staff);
			  if (staff.error) {
				  yield put(showErrorMessage(staff.error.message));
			  } else {
				  yield put(updateStaffSuccess(staff.data));
			  }
		  } catch (err) {
			  console.log(err)
			  // yield put(showAuthMessage(err));
		  }
	  });
  }




  export function* changePassword() {
	yield takeEvery(CHANGE_PASSWORD, function* ({payload}) {
		  try {
			  const result = yield call(staffService.changePassword, payload);
			  if (result.error) {
				  yield put(showErrorMessage(result.error.message));
			  } else {
				  yield put(changePasswordSuccess());
			  }
		  } catch (err) {
			  console.log(err)
			  // yield put(showAuthMessage(err));
		  }
	  });
  }









export default function* rootSaga() {
  yield all([
		fork(addStaff),
		fork(getStaffs),
		fork(updateStaff),
		fork(getStaff),
		fork(changePassword),
	  ]);
}
