import fetch from 'auth/FetchInterceptor'

const MeetingService = {}

MeetingService.createMeeting = function (data) {
	return fetch({
		url: '/meet',
		method: 'post',
		data: data
	})

}


MeetingService.joinMeeting = function (data) {
	return fetch({
		url: '/meet/join',
		method: 'post',
		data: data
	})

}

MeetingService.getMeeting = function (meetingId, params) {
	return fetch({
	  url: `/meet/${meetingId}`,
	  method: 'get',
	  params
	})
  }

  MeetingService.getMeetings = function (params) {
	return fetch({
	  url: `/meet`,
	  method: 'get',
	  params
	})
  }


  MeetingService.endMeeting = function (data) {
	return fetch({
		url: '/meet/end',
		method: 'post',
		data: data
	})

}




MeetingService.getCalendarMeetings = function (params) {
	return fetch({
	  url: `/meet/calendar`,
	  method: 'get',
	  params
	})
  }



export default MeetingService