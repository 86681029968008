// Copyright 2020-2021 OTI , Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React from 'react';
import {
  VideoTileGrid,
  UserActivityProvider
} from 'amazon-chime-sdk-component-library-react';

import { StyledLayout, StyledContent } from './Styled';
import {connect} from "react-redux"
import NavigationControl from '../container/Navigation/NavigationControl';
import { useNavigation } from '../../../providers/NavigationProvider';
import MeetingDetails from '../container/MeetingDetails';
import MeetingControls from '../container/MeetingControls';
import useMeetingEndRedirect from '../../../hooks/useMeetingEndRedirect';
import DynamicMeetingControls from '../container/DynamicMeetingControls';
import { MeetingMode } from '../../../constants/MeetingConstant';
import {endMeeting} from "redux/actions/Meeting"

const MeetingView = (props) => {
  useMeetingEndRedirect();
  const { showNavbar, showRoster } = useNavigation();

  return (
    <UserActivityProvider>
      <StyledLayout showNav={showNavbar} showRoster={showRoster}>
        <StyledContent>
          <VideoTileGrid
            className="videos"
            noRemoteVideoView={<MeetingDetails />}
          />
          {props.mode === MeetingMode.Spectator ?
            <DynamicMeetingControls endMeeting={props.endMeeting} /> :
            <MeetingControls endMeeting={props.endMeeting} />
          }
        </StyledContent>
        <NavigationControl />
      </StyledLayout>
    </UserActivityProvider>
  );
};

const mapStateToProps = (data)=>{
  return {}
}

const mapDispatchToprops = {
  endMeeting
}

export default connect(mapStateToProps, mapDispatchToprops)(MeetingView);
