import {
REQUEST_LEAVE,
REQUEST_LEAVE_SUCCESS,
GET_LEAVE_REQUESTS,
GET_LEAVE_REQUESTS_SUCCESS,
GET_STAFF_LEAVE_REQUESTS,
GET_STAFF_LEAVE_REQUESTS_SUCCESS,
UPDATE_LEAVE, 
UPDATE_LEAVE_SUCCESS,
APPROVE_LEAVE,
APPROVE_LEAVE_SUCCESS,
REJECT_LEAVE,
REJECT_LEAVE_SUCCESS,
SHOW_LOADING,
SHOW_ERROR_MESSAGE,
GET_HOLIDAYS,
GET_HOLIDAYS_SUCCESS

} from '../constants/Leave';

export const requestLeave = (leave) => {
  return {
    type: REQUEST_LEAVE,
    payload: leave
  }
};



export const requestLeaveSuccess = (leave) => {
  return {
    type: REQUEST_LEAVE_SUCCESS,
    leave,
  }
};


export const getStaffLeaves = (staffId) => {
  return {
    type: GET_STAFF_LEAVE_REQUESTS,
    payload: {staffId}
  }
};



export const getStaffLeavesSuccess = (leaves) => {
  return {
    type: GET_STAFF_LEAVE_REQUESTS_SUCCESS,
    leaves,
  }
};



export const showErrorMessage = (message) => {
  return {
    type: SHOW_ERROR_MESSAGE,
    message
  }
};


export const updateLeaveRequest = (id, leave) => {
  return {
    type: UPDATE_LEAVE,
    payload: {id, leave}
  }
};



export const updateLeaveRequestSuccess = (leave) => {
  return {
    type: UPDATE_LEAVE_SUCCESS,
    leave,
  }
};

export const approveLeaveRequest = (id, leave) => {
  return {
    type: APPROVE_LEAVE,
    payload: {id, leave}
  }
};



export const approveLeaveRequestSuccess = (leave) => {
  return {
    type: APPROVE_LEAVE_SUCCESS,
    leave,
  }
};



export const rejectLeaveRequest = (id, leave) => {
  return {
    type: REJECT_LEAVE,
    payload: {id, leave}
  }
};



export const rejectLeaveRequestSuccess = (leave) => {
  return {
    type: REJECT_LEAVE_SUCCESS,
    leave,
  }
};


export const getLeaveRequests = (query) => {
  return {
    type: GET_LEAVE_REQUESTS,
    params:query
  };
};

export const getLeaveRequestSuccess = (leaves) => {
  return {
    type: GET_LEAVE_REQUESTS_SUCCESS,
    leaves,
  };
};




export const getHolidays = () => {
  return {
    type: GET_HOLIDAYS,
  };
};

export const getHolidaysSuccess = (holidays) => {
  return {
    type: GET_HOLIDAYS_SUCCESS,
    holidays,
  };
};




export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};


