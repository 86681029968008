import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { ADD_DEPARTMENT, GET_DEPARTMENTS } from "../constants/Department";
import { addDepartmentSuccess, getDepartmentsSuccess } from "../actions/Department";

import departmentService from "services/DepartmentService";

export function* addDepartment() {
  yield takeEvery(ADD_DEPARTMENT, function* ({ payload }) {
    try {
      const department = yield call(departmentService.addDepartment, payload);
      if (department.error) {
        // yield put(showAuthMessage(user.error.message));
      } else {
        yield put(addDepartmentSuccess(department.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}


export function* getDepartments() {


	yield takeEvery(GET_DEPARTMENTS, function* () {
	  try {
		const departments = yield call(departmentService.getDepartments);
		if (departments.error) {

		  // yield put(showAuthMessage(user.message));
		} else {
		  yield put(getDepartmentsSuccess(departments.data));
		}
	  } catch (err) {
		console.log(err);
		// yield put(showAuthMessage(err));
	  }
	});
  }

export default function* rootSaga() {
  yield all([fork(addDepartment), fork(getDepartments)]);
}
