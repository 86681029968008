import React, { useEffect, useState } from "react";
import { Modal, Checkbox, Input, Button } from "antd";
import { SearchOutlined, SendOutlined } from "@ant-design/icons";
import AvatarStatus from "components/shared-components/AvatarStatus";
import moment from "moment";

const ForwardMessageForm = ({
  visible,
  handleOk,
  handleCancel,
  selectedMessage,
  rooms,
}) => {
  const [list, setList] = useState([]);
  const [selectedRooms, setSelectedRooms] = useState([]);

  const onSelect = (roomId) => {
    let newSelectedRooms;
    if (selectedRooms.includes(roomId)) {
      newSelectedRooms = selectedRooms.filter((room) => room !== roomId);
    } else {
      newSelectedRooms = [...selectedRooms, roomId];
    }
    setSelectedRooms(newSelectedRooms);
  };

  const resetSelectedRooms = () => {
    setSelectedRooms([]);
  };

  const searchOnChange = (e) => {
    const query = e.target.value;
    const data = rooms.filter((item) => {
      return query === ""
        ? item
        : item.name.toLowerCase().includes(query.toLowerCase());
    });
    setList(data);
  };

  useEffect(() => {
    setList(rooms);
  }, [rooms]);

  return (
    <Modal
      title="Forward Message"
      visible={visible}
      footer={null}
      // confirmLoading={confirmLoading}
      onCancel={handleCancel}
    >
      <div className="chat-menu">
        <div className="chat-menu-toolbar">
          <Input
            placeholder="Search"
            onChange={searchOnChange}
            prefix={<SearchOutlined className="font-size-lg mr-2" />}
          />
        </div>
        <div className="chat-menu-list">
          {list.map((item, i) => (
            <div
              key={`chat-item-${item.id}`}
              onClick={() => onSelect(item.id)}
              className={`chat-menu-list-item ${
                i === list.length - 1 ? "last" : ""
              }`}
            >
              <div>
                <Checkbox checked={selectedRooms.includes(item.id)} />
              </div>
              <AvatarStatus
                src={item.avatar}
                name={item.name}
                subTitle={item.messages[item.messages.length - 1]?.message}
              />
              <div className="text-right">
                <div className="chat-menu-list-item-time">
                  {moment(
                    item.messages[item.messages.length - 1]?.created_at
                  ).format("DD/MM/yyyy")}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          marginTop: "10px",
        }}
      >
        <div>
          <Button
            type="primary"
            block
            onClick={() =>
              handleOk(selectedMessage, selectedRooms, resetSelectedRooms)
            }
            // loading={confirmLoading}
            style={{ maxWidth: "120px", margin: "0 auto" }}
          >
            {<SendOutlined />}
            <span>Forward</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ForwardMessageForm;
