import React, {useEffect} from "react"
export function SocketListenerHoc(WrappedComponent) {
    return function HocedComp(props){
        useEffect(() => {
            if(props.io){
                props.io.on("new-message", (message)=>{
                    props.updateChatWithNewMessage(message)
                })

                props.io.on("new-chatroom", (room)=>{
                    //Initialize empty message list
                    room.messages = []
                    props.updateRoomWithNewRoom(room)
                })

                props.io.on("read-message", (chatRoomId)=>{
                    props.markChatRoomMessagesRead(chatRoomId);
                })

                props.io.on('sent-message', (message)=>{
                    props.markChatRoomMessagesSent(message)
                })

                props.io.on("update-message", (message)=>{
                    props.updateChatWithUpdatedMessage(message)
                })

                props.io.on("delete-message", (messageAndChatRoomId)=>{
                    props.removeMessageFromChat(messageAndChatRoomId)
                })
                
            }
        }, [props.io])

        return <WrappedComponent {...props} />


    } 
  }