import {
  SHOW_LOADING,
  ADD_PROJECT_SUCCESS,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_MEMBERS_SUCCESS,
  ARCHIVE_PROJECT_SUCCESS,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS
} from "../constants/Project";

const initState = {
  loading: false,
  project: null,
  message: "",
  showMessage: false,
  projects: [],
  members: [],
};

const project = (state = initState, action) => {
  switch (action.type) {
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case ADD_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        project: action.project,
        projects: [...state.projects, {completedTask:0, taskDue:0, totalTask: 0, progression:0,... action.project}],
      };


      case UPDATE_PROJECT_SUCCESS:
        const newProjects = state.projects
        const projectIndex = newProjects.findIndex((project)=>project.id===action.project.id)
        newProjects[projectIndex] = {...newProjects[projectIndex] , ...action.project};
        return {
          ...state,
          loading: false,
          project: action.project,
          projects: newProjects,
        };

    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: action.projects,
      };

    case GET_PROJECTS_MEMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        members: action.members,
      };

      case ARCHIVE_PROJECT_SUCCESS:
        return {
          ...state,
          loading: false,
          projects: state.projects.filter((project)=> project.id !== action.project.id),
        };
    default:
      return state;
  }
};

export default project;
