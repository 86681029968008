
import {CONNECT_SOCKET_IO, DISCONNECT_SOCKET_IO} from "../constants/socket.io"

export const connectSocketIo = (token) => {
    return {
      type: CONNECT_SOCKET_IO,
      token
    }
  };


  export const disconnectSocketIo = (socket) => {
    return {
      type: DISCONNECT_SOCKET_IO,
      socket
    }
  };