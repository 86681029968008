import {
  SHOW_LOADING,
  GET_STAFF_NOTIFICATIONS_SUCCESS,
} from "../constants/Notification";

const initState = {
  loading: false,
  message: "",
  showMessage: false,
  notifications: [],
};

const notification = (state = initState, action) => {
  switch (action.type) {
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_STAFF_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: action.notifications,
      };
    default:
      return state;
  }
};

export default notification;
