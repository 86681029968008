export const SHOW_LOADING = "SHOW_LOADING";
export const GET_USER_MEETINGS = "GET_USER_MEETINGS";
export const GET_USER_MEETINGS_SUCCESS = "GET_USER_MEETINGS_SUCCESS";
export const CREATE_A_MEETING = "CREATE_A_MEETING"
export const CREATE_MEETING_SUCCESS = "CREATE_MEETING_SUCCESS"


export const SET_MEETING = "SET_MEETING"


export const END_A_MEETING = "END_A_MEETING"
export const END_MEETING_SUCCESS = "END_MEETING_SUCCESS"


export const JOIN_A_MEETING = "JOIN_A_MEETING"
export const JOIN_MEETING_SUCCESS = "JOIN_MEETING_SUCCESS"


export const GET_CALENDAR_MEETINGS = "GET_CALENDAR_MEETINGS";
export const GET_CALENDAR_MEETINGS_SUCCESS = "GET_CALENDAR_MEETINGS_SUCCESS";




