export const REQUEST_LEAVE = 'REQUEST_LEAVE'; 
export const REQUEST_LEAVE_SUCCESS = 'REQUEST_LEAVE_SUCCESS'
export const GET_LEAVE_REQUESTS = 'GET_LEAVE_REQUESTS'
export const GET_LEAVE_REQUESTS_SUCCESS = 'GET_LEAVE_REQUESTS_SUCCESS'

export const GET_STAFF_LEAVE_REQUESTS = 'GET_STAFF_LEAVE_REQUESTS'
export const GET_STAFF_LEAVE_REQUESTS_SUCCESS = 'GET_STAFF_LEAVE_REQUESTS_SUCCESS'

export const UPDATE_LEAVE = 'UPDATE_LEAVE'; 
export const UPDATE_LEAVE_SUCCESS = 'UPDATE_LEAVE_SUCCESS'



export const APPROVE_LEAVE = 'APPROVE_LEAVE'; 
export const APPROVE_LEAVE_SUCCESS = 'APPROVE_LEAVE_SUCCESS'



export const REJECT_LEAVE = 'REJECT_LEAVE'; 
export const REJECT_LEAVE_SUCCESS = 'REJECT_LEAVE_SUCCESS'

export const SHOW_LOADING = 'SHOW_LOADING'
export const SHOW_ERROR_MESSAGE = "SHOW_ERROR_MESSAGE"
export const RESET_TO_INITIAL = "RESET_TO_INITIAL"



export const GET_HOLIDAYS = 'GET_HOLIDAYS'
export const GET_HOLIDAYS_SUCCESS = 'GET_HOLIDAYS_SUCCESS'