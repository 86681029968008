import React, { useState, useEffect } from "react";
import { VoiceFocusTransformDevice } from "amazon-chime-sdk-js";
import {
  ControlBarButton,
  useMeetingManager,
  useVoiceFocus,
  Microphone,
  Chat
} from "amazon-chime-sdk-component-library-react";

const VoiceFocusControl = () => {
  const meetingManager = useMeetingManager();
  const [isVoiceFocusOn, setIsVoiceFocusOn] = useState(false);
  const [isVoiceFocusEnabled, setIsVoiceFocusEnabled] = useState(false);
  const [device, setDevice] = useState(meetingManager.selectedAudioInputDevice);
  const { isVoiceFocusSupported, addVoiceFocus } = useVoiceFocus();

  useEffect(() => {
    meetingManager.subscribeToSelectedAudioInputTransformDevice(setDevice);
    return () => {
      meetingManager.unsubscribeFromSelectedAudioInputTransformDevice(
        setDevice
      );
    };
  }, []);

  useEffect(() => {
    if (device instanceof VoiceFocusTransformDevice) {
      setIsVoiceFocusEnabled(true);
    } else {
      setIsVoiceFocusEnabled(false);
    }
  }, [device]);

  useEffect(() => {
    async function toggleVoiceFocus() {
      let current = device;
      if (isVoiceFocusOn) {
        if (typeof device === "string") {
          current = await addVoiceFocus(device);
        }
      } else {
        if (device instanceof VoiceFocusTransformDevice) {
          current = device.getInnerDevice();
        }
      }
      await meetingManager.selectAudioInputDevice(current);
    }

    toggleVoiceFocus();
  }, [isVoiceFocusOn]);

  const onClick = () => {
    setIsVoiceFocusOn((current) => !current);
  };

  return (
    <div>
      {isVoiceFocusSupported && (
        <ControlBarButton
          variant="primary"
          onClick={onClick}
          icon={<Microphone />}
          label={isVoiceFocusEnabled ? "Unfocus" : "Focus"}
        />
      )}
    </div>
  );
};

export default VoiceFocusControl;
