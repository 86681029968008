import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  REQUEST_LEAVE,
  GET_LEAVE_REQUESTS,
  GET_STAFF_LEAVE_REQUESTS,
  UPDATE_LEAVE,
  APPROVE_LEAVE,
  REJECT_LEAVE,
  GET_HOLIDAYS,
} from "../constants/Leave";
import {
  requestLeaveSuccess,
  getLeaveRequestSuccess,
  showErrorMessage,
  getStaffLeavesSuccess,
  updateLeaveRequestSuccess,
  getHolidaysSuccess,
} from "../actions/Leave";

import leaveServervice from "services/LeaveService";

export function* requestLeave() {
  yield takeEvery(REQUEST_LEAVE, function* ({ payload }) {
    try {
      const leave = yield call(leaveServervice.requestLeave, payload);
      if (leave.error) {
        yield put(showErrorMessage(leave.error.message));
      } else {
        yield put(requestLeaveSuccess(leave.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}

export function* getLeaveRequests() {
  yield takeEvery(GET_LEAVE_REQUESTS, function* ({ params }) {
    try {
      const leaves = yield call(leaveServervice.getLeaves, params);
      if (leaves.error) {
        // yield put(showAuthMessage(user.message));
      } else {
        yield put(getLeaveRequestSuccess(leaves.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}

export function* getStaffLeaveRequests() {
  yield takeEvery(GET_STAFF_LEAVE_REQUESTS, function* ({ payload }) {
    try {
      const leaves = yield call(
        leaveServervice.getStaffLeaves,
        payload.staffId
      );
      if (leaves.error) {
        // yield put(showAuthMessage(user.message));
      } else {
        yield put(getStaffLeavesSuccess(leaves.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}

export function* updateLeave() {
  yield takeEvery(UPDATE_LEAVE, function* ({ payload }) {
    try {
      const leave = yield call(
        leaveServervice.updateLeave,
        payload.id,
        payload.leave
      );
      if (leave.error) {
        yield put(showErrorMessage(leave.error.message));
      } else {
        yield put(updateLeaveRequestSuccess(leave.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}

export function* approveLeave() {
  yield takeEvery(APPROVE_LEAVE, function* ({ payload }) {
    try {
      const leave = yield call(
        leaveServervice.approveLeave,
        payload.id,
        payload.leave
      );
      if (leave.error) {
        yield put(showErrorMessage(leave.error.message));
      } else {
        yield put(updateLeaveRequestSuccess(leave.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}

export function* rejectLeave() {
  yield takeEvery(REJECT_LEAVE, function* ({ payload }) {
    try {
      const leave = yield call(
        leaveServervice.rejectLeave,
        payload.id,
        payload.leave
      );
      if (leave.error) {
        yield put(showErrorMessage(leave.error.message));
      } else {
        yield put(updateLeaveRequestSuccess(leave.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}

export function* getHolidays() {
  yield takeEvery(GET_HOLIDAYS, function* ({}) {
    try {
      const holidays = yield call(leaveServervice.getHolidays);
      if (holidays.error) {
        // yield put(showAuthMessage(user.message));
      } else {
        yield put(getHolidaysSuccess(holidays.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(requestLeave),
    fork(getLeaveRequests),
    fork(getStaffLeaveRequests),
    fork(updateLeave),
    fork(approveLeave),
    fork(rejectLeave),
    fork(getHolidays),
  ]);
}
