import React from 'react'
import { Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types'

const CaretDropdown = props => {
	return (
		<Dropdown overlay={props.menu} placement={props.placement} trigger={['click']}>
			<div className="">
				<DownOutlined />
			</div>
		</Dropdown>
	)
}




export default CaretDropdown
