// Copyright 2020-2021 OTI , Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useState } from 'react';
import {
  SpeakerSelection,
  SecondaryButton,
  useAudioOutputs
} from 'amazon-chime-sdk-component-library-react';

import {Button} from "antd"

import TestSound from '../../../../utils/TestSound';

const SpeakerDevices = () => {
  const { selectedDevice } = useAudioOutputs();
  const [selectedOutput, setSelectedOutput] = useState(selectedDevice);

  const handleChange = (deviceId) => {
    setSelectedOutput(deviceId);
  };

  const handleTestSpeaker = () => {
    new TestSound(selectedOutput);
  };

  return (
    <div>
      <SpeakerSelection onChange={handleChange} />
      <Button onClick={handleTestSpeaker} type="primary">Test speakers</Button>
      {/* <SecondaryButton label="Test speakers" onClick={handleTestSpeaker} /> */}
    </div>
  );
};

export default SpeakerDevices;
