import { combineReducers } from "redux";
import Auth from "./Auth";
import Staff from "./Staff";
import Department from "./Department";
import Project from "./Project";
import Task from "./Task";
import Chat from "./Chat";
import Theme from "./Theme";
import SocketIo from "./socket.io";
import Meeting from "./Meeting";
import Leave from "./Leave";
import Notice from "./NoticeBoard";
import Idm from "./Idm";
import Notification from "./Notification"

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  staff: Staff,
  department: Department,
  project: Project,
  task: Task,
  chat: Chat,
  socket: SocketIo,
  meeting: Meeting,
  leave: Leave,
  notice: Notice,
  idm: Idm,
  notification: Notification,
});

export default reducers;
