import {
    INITIATE_CHAT,
    INITIATE_CHAT_SUCCESS,
    SHOW_LOADING,
    GET_USER_ROOMS,
    GET_USER_ROOMS_SUCCESS,
    GET_ROOM_CHATS,
    GET_ROOM_CHATS_SUCCESS,
    NEW_MESSAGE_RECEIVED,
    JOINED_NEW_ROOM,
    MARK_CHATROOM_MESSAGES_READ,
    MARK_CHATROOM_MESSAGES_SENT,
    UPDATED_MESSAGE_RECEIVED,
    REMOVE_MESSAGE_FROM_CHAT
  } from "../constants/Chat";
  
  export const initiateChat = (data) => {
    return {
      type: INITIATE_CHAT,
      payload: data,
    };
  };
  
  export const initiateChatSuccess = (room) => {
    return {
      type: INITIATE_CHAT_SUCCESS,
      room,
    };
  };
  
  export const getUserRooms = (userId, meetingId) => {
    return {
      type: GET_USER_ROOMS,
      userId,
      params: { meetingId },
    };
  };
  
  export const getUserRoomsSuccess = (rooms) => {
    return {
      type: GET_USER_ROOMS_SUCCESS,
      rooms,
    };
  };






  export const getRoomChats = (roomId) => {
    return {
      type: GET_ROOM_CHATS,
      roomId
    };
  };
  
  export const getRoomChatsSuccess = (chats) => {
    return {
      type: GET_ROOM_CHATS_SUCCESS,
      chats,
    };
  };



  export const updateChatWithNewMessage = (message) => {
    return {
      type: NEW_MESSAGE_RECEIVED,
      message,
    };
  };



  export const updateRoomWithNewRoom = (room) => {
    return {
      type: JOINED_NEW_ROOM,
      room,
    };
  };



  export const markChatRoomMessagesRead = (chatRoomId) => {
    return {
      type: MARK_CHATROOM_MESSAGES_READ,
      chatRoomId,
    };
  };

  export const markChatRoomMessagesSent = (message) => {
    return {
      type: MARK_CHATROOM_MESSAGES_SENT,
      message,
    };
  };





  export const updateChatWithUpdatedMessage = (message) => {
    return {
      type: UPDATED_MESSAGE_RECEIVED,
      message,
    };
  };


  export const removeMessageFromChat = ({messageId, chatRoomId}) => {
    return {
      type: REMOVE_MESSAGE_FROM_CHAT,
      message:{messageId, chatRoomId},
    };
  };  
  export const showLoading = () => {
    return {
      type: SHOW_LOADING,
    };
  };
  