import {
  SHOW_LOADING,
  ADD_STAFF_SUCCESS,
  UPDATE_STAFF_SUCCESS,
  GET_STAFFS_SUCCESS,
  GET_STAFF_SUCCESS,
  SHOW_ERROR_MESSAGE,
  RESET_TO_INITIAL,
  CHANGE_PASSWORD_SUCCESS

} from "../constants/Staff";



const initState = {
  loading: false,
  staff: null,
  staffs:[],
  message: "",
  messageType: null,
  showMessage: false,
  redirect: false,
};

const staff = (state = initState, action) => {
  switch (action.type) {
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SHOW_ERROR_MESSAGE:
      return {
        ...state,
        message: action.message,
        messageType: "error",
        showMessage: true,
        loading: false,
      };

    case ADD_STAFF_SUCCESS:
      return {
        ...state,
        loading: false,
        showMessage: true,
        message: `${action.staff.profile.firstName} added`,
        messageType: "success",
        staff: action.staff,
        redirect: true,
      };


	  case UPDATE_STAFF_SUCCESS:
		return {
		  ...state,
		  loading: false,
		  showMessage: true,
		  message: `${action.staff.profile.firstName} information updated`,
		  messageType: "success",
		  staff: action.staff,
		  redirect: true,
		};

	  case GET_STAFFS_SUCCESS:
		return {
			...state,
			loading: false,
			staffs: action.staffs
		}


		case GET_STAFF_SUCCESS:
			return {
				...state,
				loading: false,
				staff: action.staff
			}

    case RESET_TO_INITIAL:
      const resetObj = action.fields.reduce((acc, field) => {
        acc[field] = initState[field];
        return acc;
      }, {});
      return {
        ...state,
        ...resetObj,
      };


      case CHANGE_PASSWORD_SUCCESS:
        return {
          ...state,
          loading: false,
          showMessage: true,
          message: `Password Successfully changed`,
          messageType: "success",
          redirect: true,
        };
    default:
      return state;
  }
};

export default staff;
