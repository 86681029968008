import React, { lazy, Suspense, useEffect } from "react";
import { connect } from "react-redux";
import { SocketListenerHoc } from "sockets/socket.listeners";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH, ERROR_PREFIX_PATH } from "configs/AppConfig";
import { connectSocketIo, disconnectSocketIo } from "redux/actions/Socket.io";
import {
  updateChatWithNewMessage,
  updateRoomWithNewRoom,
  markChatRoomMessagesRead,
  updateChatWithUpdatedMessage,
  removeMessageFromChat,
  markChatRoomMessagesSent,
} from "redux/actions/Chat";

export const AppViews = ({
  token,
  io,
  connectSocketIo,
  disconnectSocketIo,
}) => {
  useEffect(() => {
    connectSocketIo(token);
    return () => {
      disconnectSocketIo(io);
    };
  }, []);

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/dashboard/profile`}
          component={lazy(() => import(`./setting`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/dashboard/staffs`}
          component={lazy(() => import(`./staff`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/dashboard/departments`}
          component={lazy(() => import(`./department`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/dashboard/meetings`}
          component={lazy(() => import(`./meetings`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/dashboard/projects`}
          component={lazy(() => import(`./project`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/dashboard/chat`}
          component={lazy(() => import(`./chat`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/dashboard/file-manager`}
          component={lazy(() => import(`./file-manager`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/dashboard/rooms`}
          component={lazy(() => import(`./rooms`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/dashboard/notices`}
          component={lazy(() => import(`./notice-board`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/dashboard/leaves`}
          component={lazy(() => import(`./leaves`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/dashboard/roles`}
          component={lazy(() => import(`./roles`))}
        />

        <Redirect
          exact
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/dashboard/projects`}
        />
        <Redirect to={`${ERROR_PREFIX_PATH}/404`} />
      </Switch>
    </Suspense>
  );
};

const mapStateToProps = ({ auth, socket }) => {
  const { token } = auth;
  const { io } = socket;

  return { token, io };
};

const mapDispatchToProps = {
  connectSocketIo,
  disconnectSocketIo,
  updateChatWithNewMessage,
  updateRoomWithNewRoom,
  markChatRoomMessagesRead,
  updateChatWithUpdatedMessage,
  removeMessageFromChat,
  markChatRoomMessagesSent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(SocketListenerHoc(AppViews)));
