import {
  ADD_PROJECT,
  ADD_PROJECT_SUCCESS,
  SHOW_LOADING,
  GET_PROJECTS,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_MEMBERS,
  GET_PROJECTS_MEMBERS_SUCCESS,
  ARCHIVE_PROJECT,
  ARCHIVE_PROJECT_SUCCESS,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS

  
} from "../constants/Project";

export const addProject = (project) => {
  return {
    type: ADD_PROJECT,
    payload: project,
  };
};

export const addProjectSuccess = (project) => {
  return {
    type: ADD_PROJECT_SUCCESS,
    project,
  };
};




export const updateProject = (project) => {
  return {
    type: UPDATE_PROJECT,
    payload: project,
  };
};

export const updateProjectSuccess = (project) => {
  return {
    type: UPDATE_PROJECT_SUCCESS,
    project,
  };
};




export const getProjects = () => {
  return {
    type: GET_PROJECTS,
  };
};

export const getProjectsSuccess = (projects) => {
  return {
    type: GET_PROJECTS_SUCCESS,
    projects,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};




export const getProjectsMembers = (projectId) => {
  return {
    type: GET_PROJECTS_MEMBERS,
    projectId
  };
};

export const getProjectsMembersSuccess = (members) => {
  return {
    type: GET_PROJECTS_MEMBERS_SUCCESS,
    members,
  };
};



export const archiveProject = (projectId) => {
  return {
    type: ARCHIVE_PROJECT,
    projectId,
  };
};

export const archiveProjectSuccess = (project) => {
  return {
    type: ARCHIVE_PROJECT_SUCCESS,
    project
    };
};




