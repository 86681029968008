// Copyright 2020-2021 OTI , Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useState, useContext, useEffect, ChangeEvent } from "react";
import { useHistory } from "react-router-dom";
import { Input, Checkbox, Form, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Card, Row, Col, Alert } from "antd";
import { motion } from "framer-motion";
import Flex from "components/shared-components/Flex";
import {
  Heading,
  useMeetingManager,
  Modal,
  ModalBody,
  ModalHeader,
  DeviceLabels,
} from "amazon-chime-sdk-component-library-react";

import { getErrorContext } from "../../../../providers/ErrorProvider";
import routes from "../../../../constants/routes";
import DevicePermissionPrompt from "../DevicePermissionPrompt";
import { useMeetingState } from "../../../../providers/MeetingStateProvider";
import { MeetingMode } from "../../../../constants/MeetingConstant";

const MeetingForm = ({
  loading,
  joinInfo,
  joinMeeting,
  showMessage,
  message,
  meetingId: defaultMeetingId,
  user,
}) => {
  const [form] = Form.useForm();
  const meetingManager = useMeetingManager();
  const {
    setAppMeetingInfo,
    region: appRegion,
    meetingId: appMeetingId,
  } = useMeetingState();
  const [meetingId, setMeetingId] = useState(appMeetingId);
  const [isLoading, setIsLoading] = useState(false);
  const [isSpectatorModeSelected, setIsSpectatorModeSelected] = useState(false);
  const [region, setRegion] = useState(appRegion);
  const { errorMessage, updateErrorMessage } = useContext(getErrorContext());
  const history = useHistory();
  const { setMeetingMode } = useMeetingState();

  // async function getAttendee(){(chimeAttendeeId, externalUserId)=> {
  //   return { name: `${user.profile.firstName} ${user.profile.otherName} ${user.profile.lastName}`};
  // }

  async function joinMeetingWithInfo({ JoinInfo, meetingInfo }) {
    async function getAttendee(chimeAttendeeId, externalUserId) {
      const userAtendee = meetingInfo.attendees.find(
        (attendee) => attendee.id == externalUserId
      );
      return {
        name: `${userAtendee.profile.firstName} ${
          userAtendee.profile.otherName ?? ""
        } ${userAtendee.profile.lastName}`,
      };
    }
    meetingManager.getAttendee = getAttendee;
    await meetingManager.join({
      meetingInfo: JoinInfo.Meeting,
      attendeeInfo: JoinInfo.Attendee,
      deviceLabels:
        isSpectatorModeSelected === true
          ? DeviceLabels.None
          : DeviceLabels.AudioAndVideo,
    });

    setAppMeetingInfo(
      meetingId,
      `${user.profile.firstName} ${user.profile.otherName ?? ""} ${
        user.profile.lastName
      }`,
      meetingInfo.region,
      meetingInfo.title,
      meetingInfo.description,
      JoinInfo.chatRoomId,
      user.id === meetingInfo.host.id
    );
    if (isSpectatorModeSelected === true) {
      setMeetingMode(MeetingMode.Spectator);
      await meetingManager.start();
      history.push(`${routes.MEETING}/${meetingId}`);
    } else {
      setMeetingMode(MeetingMode.Attendee);
      history.push(routes.DEVICE);
    }
  }

  useEffect(() => {
    if (joinInfo) {
      joinMeetingWithInfo(joinInfo);
    }
  }, [joinInfo]);

  const handleJoinMeeting = async (values) => {
    setMeetingId(values.meetingId);
    setRegion(values.region);
    setIsSpectatorModeSelected(values.isSpectatorModeSelected);
    await joinMeeting(values);
  };

  const closeError = () => {
    updateErrorMessage("");
    setMeetingId("");
    setIsLoading(false);
  };

  // const handleHideAuthMessage = () => {
  //   if (showMessage) {
  //     hideAuthMessage();
  //   }
  // };

  const backgroundStyle = {
    backgroundImage: "url(/img/others/img-17.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  return (
    <div className="h-100" style={backgroundStyle}>
      <div className="container d-flex flex-column justify-content-center h-100">
        <Row justify="center">
          <Col xs={20} sm={20} md={20} lg={9}>
            <Card>
              <div className="my-2">
                <div className="text-center">
                  <h3 className="mt-3 font-weight-bold">Join Meeting</h3>
                  <p className="mb-4">
                    Enter the meeting ID to join the meeting
                  </p>
                </div>
                <Row justify="center">
                  <Col xs={24} sm={24} md={20} lg={20}>
                    <>
                      <motion.div
                        initial={{ opacity: 0, marginBottom: 0 }}
                        animate={{
                          opacity: showMessage ? 1 : 0,
                          marginBottom: showMessage ? 20 : 0,
                        }}
                      >
                        {showMessage && (
                          <Alert
                            type="error"
                            showIcon
                            message={message}
                          ></Alert>
                        )}
                      </motion.div>
                      <Form
                        layout="vertical"
                        form={form}
                        name="join-meeting-form"
                        onFinish={handleJoinMeeting}
                      >
                        <Form.Item
                          name="meetingId"
                          label="Meeting ID"
                          initialValue={defaultMeetingId}
                          rules={[
                            {
                              required: true,
                              message: "Please input the meeting ID",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name="isSpectatorModeSelected"
                          valuePropName="checked"
                          noStyle
                        >
                          <Checkbox>Join without Audio and Video</Checkbox>
                        </Form.Item>

                        <Flex alignItems="center">
                          <Form.Item style={{ margin: "20px auto" }}>
                            <Button
                              type="primary"
                              htmlType="submit"
                              block
                              loading={isLoading}
                              style={{ maxWidth: "120px" }}
                            >
                              {!isLoading && <PlusOutlined />}
                              <span>Join</span>
                            </Button>
                          </Form.Item>
                        </Flex>

                        {errorMessage && (
                          <Modal size="md" onClose={closeError}>
                            <ModalHeader title={`Meeting ID: ${meetingId}`} />
                            <ModalBody>
                              <Card
                                title="Unable to join meeting"
                                description="There was an issue finding that meeting. The meeting may have already ended, or your authorization may have expired."
                                smallText={errorMessage}
                              />
                            </ModalBody>
                          </Modal>
                        )}
                        <DevicePermissionPrompt />
                      </Form>
                    </>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MeetingForm;
