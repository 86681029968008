import axios from 'axios'
import history from '../history'
import { AUTH_TOKEN } from 'redux/constants/Auth'
import { notification } from 'antd';
import { SmileOutlined } from '@ant-design/icons';


const service = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 60000
})

// Config
const ENTRY_ROUTE = '/auth/login'
const TOKEN_PAYLOAD_KEY = 'authorization'
const PUBLIC_REQUEST_KEY = 'public-request'
const PAGE_REQUEST_KEY = 'page-request'

// API Request interceptor
service.interceptors.request.use(config => {
	const jwtToken = localStorage.getItem(AUTH_TOKEN)
	
  if (jwtToken) {
    config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`
  }

  if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
		history.push(ENTRY_ROUTE)
		window.location.reload();
  }

  return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Error',
		placement:"bottomLeft",
		duration: 0
	})
  Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use( (response) => {
	return response.data
}, (error) => {


	let notificationParam = {
		duration: 0,
		message: 'An error occurred'
	}
	if((error.response.status=== 400 || error.response.status === 404) && !error.response.config.headers[PAGE_REQUEST_KEY]){
		return error.response.data;
	}
	
	// Remove token and redirect 
	if (error.response.status === 401) {
		notificationParam.message = 'Authentication Fail'
		notificationParam.description = 'Please login again'
		localStorage.removeItem(AUTH_TOKEN)
		history.push(ENTRY_ROUTE)
		window.location.reload();
	}

	if (error.response.status === 404) {
		notificationParam.message = 'Not Found'
		window.location.href = "/error/404"
	}

	if (error.response.status === 500) {
		notificationParam.message = 'Internal Server Error'
		window.location.href = "/error/500"


	}

	if (error.response.status === 403) {
		notificationParam.message = 'You don\'t have all the required permissions to perform this action'

	}
	
	if (error.response.status === 508) {
		notificationParam.message = 'Time Out'

	}

	// notification.error(notificationParam)


	notification.error(notificationParam);

	return Promise.reject(error);
});

export default service