import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import {
  Avatar,
  Divider,
  Input,
  Form,
  Button,
  Menu,
  Upload,
  Dropdown,
  Image,
  Badge,
} from "antd";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import {
  FileOutlined,
  SendOutlined,
  PaperClipOutlined,
  SmileOutlined,
  AudioMutedOutlined,
  UserOutlined,
  DeleteOutlined,
  CloseOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import Flex from "components/shared-components/Flex";
import EllipsisDropdown from "components/shared-components/EllipsisDropdown";
import CaretDropdown from "components/shared-components/CaretDropdown";
import AssigneeAvatar from "components/shared-components/AssigneeAvatar";
import Utils from "utils";
import GroupForm from "./GroupForm";
import AttachmentForm from "./AttachmentForm";
import ForwardMessageForm from "./ForwardMessageForm";
import {
  DATE_FORMAT_HH_mm,
  DATE_FORMAT_DD_MM_YYYY_WITH_SLASH,
  DATE_FORMAT_YYYY_MM_DD_HH_mm,
} from "constants/DateConstant";
import { AUTH_USER } from "redux/constants/Auth";

import FileManager from "components/files-manager-components";

import AutoLinkText from "react-autolink-text2";
import  EmptyState  from 'components/shared-components/EmptyState';

const profileData = JSON.parse(localStorage.getItem(AUTH_USER));

const menu =
  ({ showGroupForm }) =>
  () =>
    (
      <Menu>
        <Menu.Item key="0" onClick={showGroupForm}>
          <UserOutlined />
          <span>Create Group</span>
        </Menu.Item>
        <Menu.Item key="1">
          <AudioMutedOutlined />
          <span>Mute Chat</span>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="3">
          <DeleteOutlined />
          <span>Delete Chat</span>
        </Menu.Item>
      </Menu>
    );

const messageMenu =
  ({
    message,
    showFordMessageForm,
    editMessage,
    deleteMessage,
    replyMessage,
    from,
    isMeetingChat,
  }) =>
  () =>
    (
      <Menu>
        {!isMeetingChat && (
          <Menu.Item key="0" onClick={() => showFordMessageForm(message)}>
            <SendOutlined />
            <span>Forward Message</span>
          </Menu.Item>
        )}
        <Menu.Item key="1" onClick={() => replyMessage(message)}>
          <SendOutlined />
          <span>Reply Message</span>
        </Menu.Item>
        {from === "me" && (
          <>
            <Menu.Divider />

            <Menu.Item key="2" onClick={() => editMessage(message)}>
              <EditOutlined />
              <span>Edit Message</span>
            </Menu.Item>
          </>
        )}

        {from === "me" && (
          <>
            <Menu.Divider />
            <Menu.Item key="3" onClick={() => deleteMessage(message)}>
              <DeleteOutlined />
              <span>Delete Chat</span>
            </Menu.Item>
          </>
        )}
      </Menu>
    );

function FilePicker({ pickerCallback }) {
  return (
    <FileManager pickerCallback={pickerCallback} enableUploadView={true}>
      <a href="#" className="text-dark font-size-lg mr-3">
      <img src="/img/others/google-drive-logo.svg" alt="connect drive" height={18} />
      </a>
    </FileManager>
  );
}

export function Conversation({ isMeetingChat, ...props }) {
  const formRef = useRef();
  const editMessageRef = useRef();

  const chatBodyRef = React.createRef();

  const [info, setInfo] = useState({});
  const [msgList, setMsgList] = useState([]);
  const [groupFormVisible, setGroupFormVisible] = useState(false);
  const [forwardFormVisible, setForwardFormVisible] = useState(false);
  const [isReplyingToMessage, setIsReplyingToMessage] = useState(false);

  const [selectedMessage, setSelectedMessage] = useState();
  const [isEditMessage, setIsEditMessage] = useState(false);

  const [attachment, setAttachment] = useState(false);

  const [isUpdate, setIsUpdate] = useState(false);
  const [form] = Form.useForm();
  const [attachmentForm] = Form.useForm();
  const [avatarUrl, setAvatarUrl] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  useEffect(
    () => {
      getConversation(getChatRommId());
    },
    !isMeetingChat ? [props.rooms, props.match.params.id] : [props.rooms]
  );

  const getChatRommId = () => {
    if (!isMeetingChat) {
      const { id } = props.match.params;
      return id;
    } else {
      return props.chatRoomId;
    }
  };

  const getConversation = (currentId) => {
    const data = props.rooms.filter((elm) => elm.id === currentId);
    setInfo(data[0]);
    setMsgList(data[0]?.messages ?? []);
  };

  useEffect(() => scrollToBottom(), [msgList]);

  const handleCancelGroupForm = () => {
    form.resetFields();
    setIsUpdate(false);
    setGroupFormVisible(false);
  };

  const showGroupForm = () => {
    setIsUpdate(false);
    setGroupFormVisible(true);
  };

  const showFordMessageForm = (message) => {
    setSelectedMessage(message);
    setForwardFormVisible(true);
  };

  const editMessage = (message) => {
    setSelectedMessage(message);
    setIsEditMessage(true);
  };

  const replyMessage = (message) => {
    setSelectedMessage(message);
    setIsReplyingToMessage(true);
  };

  const closeReplyMessage = () => {
    setIsReplyingToMessage(false);
    setSelectedMessage(null);
  };

  const handleCancelForwardMessageForm = () => {
    setForwardFormVisible(false);
  };

  const closeEditMessage = () => {
    setIsEditMessage(false);
    setSelectedMessage(null);
  };

  const handleGetBase64 = (file) => {
    Utils.getBase64(file, (imageUrl) => {
      setAvatarUrl(imageUrl);
    });

    return false;
  };

  const onRemoveAvater = () => {
    setAvatarUrl("");
  };

  const getMsgType = (obj) => {
    switch (obj.type) {
      case "text":
        return <AutoLinkText text={obj.message} linkProps={{target:"_blank"}}   disableUrlStripping={true}/>;

      case "image":
        return (
          <>
            {" "}
            <Image src={obj.attachment.url} />
            {/* <img src={obj.attachment.url} alt={obj.attachment.name} /> */}
            {obj.message && (
              <div>
                <AutoLinkText linkProps={{_target:"blank"}}  text={obj.message} />{" "}
              </div>
            )}
          </>
        );
      case "file":
        return (
          <div>
            <Flex alignItems="center" className="msg-file">
              <FileOutlined className="font-size-md" />
              <a
                href={obj.attachment.url}
                className="ml-2 font-weight-semibold text-link pointer"
                download={obj.attachment.name}
              >
                <u>{obj.attachment.name}</u>
              </a>
            </Flex>
            {obj.message && <AutoLinkText text={obj.message}/>}
          </div>
        );
      default:
        return null;
    }
  };

  const scrollToBottom = () => {
    chatBodyRef.current.scrollToBottom();
  };

  const onEdit = (values) => {
    if (values.message) {
      const messageData = {
        messageId: selectedMessage.id,
        message: values.message,
      };

      // // Send a message
      props.socket.emit("update-message", messageData);
      selectedMessage.message = values.message;
      props.updateChatWithUpdatedMessage(selectedMessage);
      editMessageRef.current.setFieldsValue({
        message: "",
      });
      closeEditMessage();
    }
  };

  const onDelete = (message) => {
    // // Send a message
    props.socket.emit("delete-message", message.id);
    props.removeMessageFromChat({
      messageId: message.id,
      chatRoomId: getChatRommId(),
    });
  };

  const onSend = (values) => {
    if (values.newMsg) {
      let newMsgData = {
        type: "text",
        message: values.newMsg,
        chatRoom: getChatRommId(),
        key: new Date().getTime(),
      };

      let updateData = {
        ...newMsgData,
        from: "me",
        sent: false,
        created_at: new Date(),
        chatRoom: { id: getChatRommId() },
        postedBy: profileData,
      };
      if (isReplyingToMessage && selectedMessage) {
        newMsgData = { ...newMsgData, repliedToMessage: selectedMessage?.id };
        updateData = {
          ...newMsgData,
          from: "me",
          sent: false,
          created_at: new Date(),
          chatRoom: { id: getChatRommId() },
          repliedToMessage: selectedMessage,
          postedBy: profileData,
        };
      }
      // Send a message
      props.socket.emit("new-message", newMsgData);
      // update a message list
      props.updateChatWithNewMessage(updateData);

      formRef.current.setFieldsValue({
        newMsg: "",
      });
      closeReplyMessage();
    }
  };

  const handleForwardMessage = (message, chatRoomIds, callback) => {
    props.socket.emit("forward-message", {
      chatRoomIds,
      messageId: message.id,
    });

    chatRoomIds.forEach((chatRoom) => {
      const newMsgData = {
        type: message.type,
        message: message.message,
        attachment: message.attachment,
        created_at: new Date(),
      };

      props.updateChatWithNewMessage({
        ...newMsgData,
        from: "me",
        chatRoom: { id: chatRoom },
      });
    });
    setForwardFormVisible(false);
    setSelectedMessage(null);
    if (callback) {
      callback();
    }
  };

  const onSelectEmoji = (e) => {
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    formRef.current.setFieldsValue({
      newMsg: `${formRef.current.getFieldValue("newMsg") ?? ""}${emoji}`,
    });
  };

  const onSelectFile = (data) => {
    if (data.action == window.google.picker.Action.PICKED) {
      data.docs.map((doc) =>
        formRef.current.setFieldsValue({
          newMsg: `${formRef.current.getFieldValue("newMsg") ?? ""}${doc.url} `,
        })
      );
    }
  };

  const emptyClick = (e) => {
    e.preventDefault();
  };

  const handleShowEmojiPicker = (e) => {
    e.preventDefault();
    setShowEmojiPicker(!showEmojiPicker);
  };

  const chatContentHeader = (info) => (
    <div className="chat-content-header">
      <div>
        {!isMeetingChat && <h4 className="mb-0">{info?.name}</h4>}
        {info?.type === "private-chat" && info?.otherMember && (
          <span>
            {info.otherMember.availabilityStatus === "Online"
              ? "Online"
              : `Last Online:  ${moment(info.otherMember.lastOnline).format(
                  DATE_FORMAT_YYYY_MM_DD_HH_mm
                )}`}
          </span>
        )}
      </div>

      {info?.type === "group-chat" && !isMeetingChat && (
        <div className="text-right">
          <div className="d-flex align-items-center">
            {info?.members?.map((member, i) =>
              i < 10 ? (
                <Badge
                  color={
                    member.availabilityStatus === "Online" ? "green" : "gold"
                  }
                  count={null}
                >
                  <AssigneeAvatar
                    showName={false}
                    key={i}
                    member={member}
                    size={30}
                    chain
                  />
                </Badge>
              ) : null
            )}

            {info?.members?.length > 10 && (
              <Avatar className="ml-n2" size={30}>
                <span className="text-gray font-weight-semibold font-size-base">
                  +{info?.members.length - 10}
                </span>
              </Avatar>
            )}
          </div>
        </div>
      )}

      {!isMeetingChat && (
        <div>
          <EllipsisDropdown menu={menu({ showGroupForm })} />
        </div>
      )}
    </div>
  );

  const attachmentMenu = (
    <Menu>
      <Menu.Item>
        <Upload
          showUploadList={false}
          beforeUpload={Utils.handleGetBase64(setAttachment)}
        >
          <a
            href="/#"
            className="text-dark font-size-lg mr-3"
            onClick={emptyClick}
          >
            Photo or Video
          </a>
        </Upload>
      </Menu.Item>

      <Menu.Item>
        <Upload
          showUploadList={false}
          beforeUpload={Utils.handleGetBase64(setAttachment)}
        >
          <a
            href="/#"
            className="text-dark font-size-lg mr-3"
            onClick={emptyClick}
          >
            Document
          </a>
        </Upload>
      </Menu.Item>
    </Menu>
  );

  const chatReplySection = (replyToMessage, message) => {
    return (
      <>
        {" "}
        <p
          style={{
            color: "rgb(138, 141, 145)",
            fontSize: "12px",
            lineHeight: "13px",
          }}
        >
          <svg
            viewBox="0 0 36 36"
            // class="a8c37x1j ms05siws hwsy1cff b7h9ocf4 m50y4xls"
            style={{
              color: "rgb(138, 141, 145)",
              fontSize: "12px",
              lineHeight: "13px",
            }}
            height="16"
            width="16"
          >
            <path
              fill-rule="evenodd"
              fillOpacity="0.5"
              clip-rule="evenodd"
              d="M18.005 24.4c0 1.278-1.336 2.04-2.334 1.332l-9.007-6.399c-.891-.633-.89-2.028 0-2.661l9.007-6.398c.998-.71 2.334.053 2.334 1.33l.001 3.397c7.14 0 11.997 3.226 11.998 10.987 0 .594-.411 1.012-1.033 1.012-.46 0-.845-.265-1.115-1.083-1.147-3.464-4.54-4.917-9.85-4.917v3.4z"
            ></path>
          </svg>
          {message
            ? ` ${
                profileData?.id === message.postedBy.id
                  ? "You"
                  : message.postedBy.profile.firstName
              } replied to ${
                profileData?.id === replyToMessage?.postedBy.id
                  ? "Yourself"
                  : replyToMessage?.postedBy.profile.firstName
              }`
            : `You are replying to ${
                profileData?.id === replyToMessage?.postedBy.id
                  ? "Yourself"
                  : replyToMessage?.postedBy.profile.firstName
              }`}
        </p>
        <p
          style={{
            color: "rgb(101, 103, 107)",
            fontSize: "13px",
            lineHeight: "10px",
          }}
        >
          {replyToMessage?.message}
        </p>
      </>
    );
  };

  const chatContentBody = (props, id) => (
    <div
      className="chat-content-body"
      style={
        isMeetingChat
          ? {
              height: "calc(100vh - 320px - 76px - 25px - 70px - 60px - 30px)",
              padding: "15px",
            }
          : {}
      }
    >
      <Scrollbars ref={chatBodyRef} autoHide>
        {props.length>0 ?props.map((elm, i) => {
          return (
            <>
              {(!isMeetingChat && i == 0) ||
              (props[i - 1] &&
                moment(props[i - 1].created_at).format(
                  DATE_FORMAT_DD_MM_YYYY_WITH_SLASH
                ) !==
                  moment(elm.created_at).format(
                    DATE_FORMAT_DD_MM_YYYY_WITH_SLASH
                  )) ? (
                <div key={`msg-${id}-${i}-date`} className="msg datetime">
                  <Divider>
                    {moment(elm.created_at).format(
                      DATE_FORMAT_DD_MM_YYYY_WITH_SLASH
                    )}
                  </Divider>
                </div>
              ) : null}

              {isEditMessage && selectedMessage?.id === elm.id ? (
                chatMessageEditor(elm)
              ) : (
                <div
                  key={`msg-${id}-${i}`}
                  className={`msg ${elm.type === "date" ? "datetime" : ""} ${
                    elm.from === "opposite"
                      ? "msg-recipient"
                      : elm.from === "me"
                      ? "msg-sent"
                      : ""
                  }`}
                >
                  {elm.from === "opposite" &&
                  elm.postedBy?.profile?.avatar &&
                  (i == 0 ||
                    (props[i - 1] &&
                      props[i - 1].postedBy.id !== elm.postedBy.id)) ? (
                    <div className="mr-1">
                      <Avatar src={elm.postedBy.profile.avatar} />
                    </div>
                  ) : (
                    <div className="mr-5"></div>
                  )}

                  {elm.message || elm.attachment ? (
                    <div className={`bubble ${!elm.avatar ? "ml-2" : ""}`}>
                      {info.type === "group-chat" &&
                        elm.from === "opposite" &&
                        (i == 0 ||
                          (props[i - 1] &&
                            props[i - 1].postedBy.id !== elm.postedBy.id)) && (
                          <span className="font-size-sm text-dark">
                            {elm.postedBy.profile.firstName}{" "}
                            {elm.postedBy.profile.otherName}{" "}
                            {elm.postedBy.profile.lastName}
                          </span>
                        )}
                      {elm.repliedToMessage &&
                        chatReplySection(elm.repliedToMessage, elm)}
                      <div className="bubble-wrapper">
                        <div className="d-flex justify-content-end">
                          <div className="message-dropdown">
                            <CaretDropdown
                              menu={messageMenu({
                                message: elm,
                                showFordMessageForm,
                                editMessage,
                                from: elm.from,
                                deleteMessage: onDelete,
                                replyMessage,
                                isMeetingChat,
                              })}
                            />
                          </div>
                        </div>

                        {getMsgType(elm)}

                        <div className="d-flex justify-content-end">
                          <span style={{ fontSize: "12px" }}>
                            {moment(elm.created_at).format(DATE_FORMAT_HH_mm)}{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
            </>
          );
        }): <EmptyState description="No conversation yet. Be the first to initiate a conversation" style={{marginTop:"25vh"}}/>}
        {showEmojiPicker && (
          <span>
            <Picker
              onSelect={onSelectEmoji}
              style={{ position: "absolute", bottom: "0px", right: "10px" }}
            />
          </span>
        )}
      </Scrollbars>
    </div>
  );

  const chatMessageEditor = (message) => (
    <div className="chat-content-footer">
      <Form
        name="msgInput"
        ref={editMessageRef}
        onFinish={onEdit}
        className="w-100"
      >
        <Form.Item name="message" className="mb-0">
          <Input
            autoComplete="on"
            placeholder="Type a message..."
            defaultValue={message?.message}
            suffix={
              <div className="d-flex align-items-center">
                <a
                  href="#"
                  className="text-dark font-size-lg mr-3"
                  onClick={closeEditMessage}
                >
                  <CloseOutlined />
                </a>
                <Button
                  shape="circle"
                  type="primary"
                  size="small"
                  // onClick={}
                  htmlType="submit"
                >
                  <SendOutlined />
                </Button>
              </div>
            }
          />
        </Form.Item>
      </Form>
    </div>
  );

  const chatContentFooter = () => (
    <div className="chat-content-footer">
      <Form name="msgInput" ref={formRef} onFinish={onSend} className="w-100">
        {isReplyingToMessage && selectedMessage && (
          <Flex alignItems="between" justifyContent="between" className="w-100">
            <div>{chatReplySection(selectedMessage)}</div>
            <div className="mt-3">
              <CloseOutlined onClick={closeReplyMessage} />
            </div>
          </Flex>
        )}
        <Form.Item name="newMsg" className="mb-0">
          <Input
            autoComplete="on"
            placeholder="Type a message..."
            suffix={
              <div className="d-flex align-items-center">
                {showEmojiPicker && (
                  <a
                    href="#"
                    className="text-dark font-size-lg mr-3"
                    onClick={handleShowEmojiPicker}
                  >
                    <CloseOutlined />
                  </a>
                )}
                <a
                  href="#"
                  className="text-dark font-size-lg mr-3"
                  onClick={handleShowEmojiPicker}
                >
                  <SmileOutlined />
                </a>
                {/* File manager */}
                <FilePicker
                  pickerCallback={onSelectFile}
                  enableUploadView={false}
                />

                {/* File manager */}

                <Dropdown
                  placement="topCenter"
                  trigger="click"
                  overlay={attachmentMenu}
                >
                  <a
                    href="/#"
                    className="text-dark font-size-lg mr-3"
                    onClick={emptyClick}
                  >
                    <PaperClipOutlined />
                  </a>
                </Dropdown>

                <Button
                  shape="circle"
                  type="primary"
                  size="small"
                  // onClick={onSend}
                  htmlType="submit"
                >
                  <SendOutlined />
                </Button>
              </div>
            }
          />
        </Form.Item>
      </Form>

      <GroupForm
        visible={groupFormVisible}
        confirmLoading={props.loading}
        handleOk={(values) =>
          isUpdate
            ? console.log(values)
            : props.initiateChat({
                ...values,
                avatar: avatarUrl,
                type: "group-chat",
              })
        }
        handleCancel={handleCancelGroupForm}
        staffs={props.staffs}
        getStaffs={props.getStaffs}
        isUpdate={isUpdate}
        form={form}
        avatarUrl={avatarUrl}
        handleGetBase64={handleGetBase64}
        onRemoveAvater={onRemoveAvater}
      />

      <AttachmentForm
        visible={!!attachment}
        confirmLoading={props.loading}
        form={attachmentForm}
        handleOk={(values) => {
          const value = {
            message: values.caption,
            type: attachment.type,
            attachment,
            chatRoom: getChatRommId(),
          };

          props.socket.emit("new-message", value);
          // update a message list
          props.updateChatWithNewMessage({
            ...value,
            from: "me",
            chatRoom: { id: getChatRommId() },
          });
        }}
        handleCancel={() => setAttachment(null)}
        attachment={attachment}
        handleGetBase64={Utils.handleGetBase64(setAttachment)}
        onRemoveAvater={onRemoveAvater}
      />

      <ForwardMessageForm
        visible={forwardFormVisible}
        handleOk={handleForwardMessage}
        handleCancel={handleCancelForwardMessageForm}
        rooms={props.rooms}
        selectedMessage={selectedMessage}
      />
    </div>
  );

  return (
    <div className="chat-content">
      {!isMeetingChat && chatContentHeader(info)}
      {chatContentBody(msgList, getChatRommId())}
      {chatContentFooter()}
    </div>
  );
}

export default Conversation;
