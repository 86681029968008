export const ADD_NOTICE = 'ADD_NOTICE'; 
export const ADD_NOTICE_SUCCESS = 'ADD_NOTICE_SUCCESS'
export const GET_NOTICES = 'GET_NOTICES'
export const GET_NOTICES_SUCCESS = 'GET_NOTICES_SUCCESS'
export const SHOW_LOADING = 'SHOW_LOADING'
export const SHOW_ERROR_MESSAGE = "SHOW_ERROR_MESSAGE"
export const RESET_TO_INITIAL = "RESET_TO_INITIAL"




