import {
  SIDE_NAV_LIGHT,
  SIDE_NAV_DARK,
  NAV_TYPE_SIDE,
  DIR_LTR,
} from "../constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

export const APP_NAME = "OTI";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const ERROR_PREFIX_PATH = "/error";
export const MEET_PREFIX_PATH = "/meet";
export const OFFICE_PREFIX_PATH = "/office";



export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_DARK,
  locale: "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: DIR_LTR,
};

export const MIME_TYPES = {
  text: "text",
  image: "image",
  video: "video",
  application: "file",
};
