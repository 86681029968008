import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADD_PROJECT,
  GET_PROJECTS,
  GET_PROJECTS_MEMBERS,
  ARCHIVE_PROJECT,
  UPDATE_PROJECT
} from "../constants/Project";
import {
  addProjectSuccess,
  getProjectsSuccess,
  getProjectsMembersSuccess,
  archiveProjectSuccess,
  updateProjectSuccess
} from "../actions/Project";

import projectService from "services/ProjectService";

export function* addProject() {
  yield takeEvery(ADD_PROJECT, function* ({ payload }) {
    try {
      const project = yield call(projectService.addProject, payload);
      if (project.error) {
        // yield put(showAuthMessage(user.error.message));
      } else {
        yield put(addProjectSuccess(project.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}

export function* getProjects() {
  yield takeEvery(GET_PROJECTS, function* () {
    try {
      const projects = yield call(projectService.getProjects);
      if (projects.error) {
        // yield put(showAuthMessage(user.message));
      } else {
        yield put(getProjectsSuccess(projects.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}





export function* getProjectMembers() {
  yield takeEvery(GET_PROJECTS_MEMBERS, function* ({projectId}) {
    try {
      const result = yield call(projectService.getProjectMembers, projectId);
      if (result.error) {
        // yield put(showAuthMessage(user.message));
      } else {
        yield put(getProjectsMembersSuccess(result.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}


export function* archiveProject() {
  yield takeEvery(ARCHIVE_PROJECT, function* ({ projectId }) {
    try {
      const project = yield call(projectService.archiveProject, projectId);
      if (project.error) {
        // yield put(showAuthMessage(user.error.message));
      } else {
        yield put(archiveProjectSuccess(project.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}



export function* updateProject() {
  yield takeEvery(UPDATE_PROJECT, function* ({ payload }) {
    try {
      const project = yield call(projectService.updateProject, payload);
      if (project.error) {
        // yield put(showAuthMessage(user.error.message));
      } else {
        yield put(updateProjectSuccess(project.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(addProject),
    fork(getProjects),
    fork(getProjectMembers),
    fork(archiveProject),
    fork(updateProject)
  ]);
}
