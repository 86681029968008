import {
    SHOW_LOADING,
    REQUEST_LEAVE_SUCCESS,
    GET_LEAVE_REQUESTS_SUCCESS,
    GET_STAFF_LEAVE_REQUESTS_SUCCESS,
    UPDATE_LEAVE_SUCCESS,
    APPROVE_LEAVE_SUCCESS,
    REJECT_LEAVE_SUCCESS,
    SHOW_ERROR_MESSAGE,
    GET_HOLIDAYS_SUCCESS,
  } from "../constants/Leave";
  
  const initState = {
    loading: false,
    leave: null,
    leaves: [],
    holidays: [],
    message: "",
    messageType: null,
    showMessage: false,
  };
  
  const leave = (state = initState, action) => {
    switch (action.type) {
      case SHOW_LOADING: {
        return {
          ...state,
          loading: true,
        };
      }
  
      case SHOW_ERROR_MESSAGE:
        return {
          ...state,
          message: action.message,
          messageType: "error",
          showMessage: true,
          loading: false,
        };
  
      case REQUEST_LEAVE_SUCCESS:
        return {
          ...state,
          loading: false,
          showMessage: true,
          message: `Leave requested`,
          messageType: "success",
          leave: action.leave,
          leaves: [...state.leaves, action.leave],
        };
  
      case GET_LEAVE_REQUESTS_SUCCESS:
        return {
          ...state,
          loading: false,
          leaves: action.leaves,
        };
  
      case GET_STAFF_LEAVE_REQUESTS_SUCCESS:
        return {
          ...state,
          loading: false,
          leaves: action.leaves,
        };
  
      case UPDATE_LEAVE_SUCCESS:
        const newLeaves = state.leaves;
        const leaveIndex = newLeaves.findIndex(
          (leave) => leave.id === action.leave.id
        );
        newLeaves[leaveIndex] = action.leave;
        return {
          ...state,
          loading: false,
          leave: action.leave,
          leaves: newLeaves,
        };
  
      case APPROVE_LEAVE_SUCCESS:
        const newLeavesWithApproveLeave = state.leaves;
        const approvLeaveIndex = newLeavesWithApproveLeave.findIndex(
          (leave) => leave.id === action.leave.id
        );
        newLeavesWithApproveLeave[approvLeaveIndex] = action.leave;
        return {
          ...state,
          loading: false,
          leave: action.leave,
          leaves: newLeavesWithApproveLeave,
        };
  
      case REJECT_LEAVE_SUCCESS:
        const newLeavesWithRejectLeave = state.leaves;
        const rejectLeaveIndex = newLeavesWithRejectLeave.findIndex(
          (leave) => leave.id === action.leave.id
        );
        newLeavesWithRejectLeave[rejectLeaveIndex] = action.leave;
        return {
          ...state,
          loading: false,
          leave: action.leave,
          leaves: newLeavesWithRejectLeave,
        };
  
      case GET_HOLIDAYS_SUCCESS:
        return {
          ...state,
          loading: false,
          holidays: action.holidays,
        };
  
      default:
        return state;
    }
  };
  
  export default leave;
  