import React from "react"
import {Empty} from "antd"

export default function EmptyState({image="/nodata.svg", description="No data", style}){
    return <Empty  image={image} description={description} style={style}/>

}








