import {
ADD_STAFF,
ADD_STAFF_SUCCESS,
UPDATE_STAFF,
UPDATE_STAFF_SUCCESS,
GET_STAFFS,
GET_STAFFS_SUCCESS,
GET_STAFF,
GET_STAFF_SUCCESS,
SHOW_LOADING,
SHOW_ERROR_MESSAGE,
RESET_TO_INITIAL,
CHANGE_PASSWORD,
CHANGE_PASSWORD_SUCCESS
} from '../constants/Staff';

export const addStaff = (staff) => {
  return {
    type: ADD_STAFF,
    payload: staff
  }
};



export const addStaffSuccess = (staff) => {
  return {
    type: ADD_STAFF_SUCCESS,
    staff,
  }
};


export const updateStaff = (id, staff) => {
  return {
    type: UPDATE_STAFF,
    payload: {id, staff}
  }
};



export const updateStaffSuccess = (staff) => {
  return {
    type: UPDATE_STAFF_SUCCESS,
    staff,
  }
};


export const getStaffs = () => {
  return {
    type: GET_STAFFS,
  };
};

export const getStaffsSuccess = (staffs) => {
  return {
    type: GET_STAFFS_SUCCESS,
    staffs,
  };
};


export const getStaff = (id) => {
  return {
    type: GET_STAFF,
    id
  };
};

export const getStaffSuccess = (staff) => {
  return {
    type: GET_STAFF_SUCCESS,
    staff,
  };
};


export const showErrorMessage = (message) => {
  return {
    type: SHOW_ERROR_MESSAGE,
    message
  };
};


export const resetToInitialState = (fields) => {
  return {
    type: RESET_TO_INITIAL,
    fields
  };
};





export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};




export const changePassword = (payload) => {
  return {
    type: CHANGE_PASSWORD,
    payload
  }
};



export const changePasswordSuccess = () => {
  return {
    type: CHANGE_PASSWORD_SUCCESS
  }
};
