import React from "react";
import { Tooltip, Avatar } from "antd";

const AssigneeAvatar = ({ member, showName = true, size = 20, chain }) => {
  return (
    <div className={`d-flex ${chain ? "ml-n2" : ""}`}>
      <Tooltip
        title={`${member.profile.firstName} ${member.profile.otherName??''} ${member.profile.lastName}`}
      >
        <Avatar
          className="cursor-pointer"
          size={size}
          src={member.profile.avatar}
          style={chain ? { border: "2px solid #fff" } : {}}
        ></Avatar>
      </Tooltip>
      {showName ? (
        <span className="mb-0 ml-2 font-weight-semibold">
          {member.profile.firstName} {member.profile.otherName} {member.profile.lastName}
        </span>
      ) : null}
    </div>
  );
};

export default AssigneeAvatar;
