import React, { useState, useEffect } from "react";
import { Menu, Dropdown, Badge, Avatar, List, Button } from "antd";
import { connect } from "react-redux";
import {
  BellOutlined,
} from "@ant-design/icons";
import NotificationItem from "./NotificationItem"
import { getStaffNotifications, showLoading } from "redux/actions/Notification";





export const NavNotification = (props) => {
  const [visible, setVisible] = useState(false);
  // const [data, setData] = useState(notificationData);

  useEffect(() => {
    props.getStaffNotifications();
  }, []);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const getNotificationBody = (list) => {
    return list.length > 0 ? (
      <List
        size="small"
        itemLayout="horizontal"
        dataSource={list}
        renderItem={(item) => <NotificationItem item={item} socket={props.io} />}
      />
    ) : (
      <div className="empty-notification">
        <img
          src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
          alt="empty"
        />
        <p className="mt-3">You have viewed all notifications</p>
      </div>
    );
  };

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notification</h4>
        {/* <Button type="link" onClick={() => setData([])} size="small">
          Clear{" "}
        </Button> */}
      </div>
      <div className="nav-notification-body">{getNotificationBody(props.notifications)}</div>
      {props.notifications.length > 0 ? (
        <div className="nav-notification-footer">
          <a className="d-block" href="#/">
            View all
          </a>
        </div>
      ) : null}
    </div>
  );

  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={["click"]}
    >
      <Menu mode="horizontal">
        <Menu.Item>
          <Badge count={props.notifications.length}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

const mapStateToProps = (data) => {
  const { notifications, loading, message, showMessage } = data.notification;
  const { io } = data.socket;

  return { loading, message, showMessage, notifications, io };
};

const mapDispatchToProps = {
  getStaffNotifications,
  showLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavNotification);
