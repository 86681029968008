import { all } from 'redux-saga/effects';
import Auth from './Auth';
import Staff from './Staff'
import Department from "./Department"
import Project from "./Project"
import Task from "./Task"
import Chat from "./Chat"
import Meeting from "./Meeting"
import Leave from "./Leave"
import NoticeBoard from "./NoticeBoard"
import Idm from "./Idm"
import Notification from "./Notification"

export default function* rootSaga(getState) {
  yield all([
    Auth(),
    Staff(),
    Department(),
    Project(),
    Task(),
    Chat(),
    Meeting(),
    Leave(),
    NoticeBoard(),
    Idm(),
    Notification()
  ]);
}
