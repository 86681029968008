import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADD_ROLE,
  UPDATE_ROLE,
  GET_ALL_PRIVILEGES,
  GET_ALL_ROLES,
} from "../constants/Idm";
import {
  addRoleSuccess,
  updateRoleSuccess,
  getAllRolesSuccess,
  getAllPrivilegesSuccess,
} from "../actions/Idm";

import idmService from "services/IdmService";

export function* addRole() {
  yield takeEvery(ADD_ROLE, function* ({ payload }) {
    try {
      const role = yield call(idmService.addRole, payload);
      if (role.error) {
        // yield put(showAuthMessage(user.error.message));
      } else {
        yield put(addRoleSuccess(role.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}

export function* updateRole() {
  yield takeEvery(UPDATE_ROLE, function* ({ payload }) {
    try {
      const role = yield call(idmService.updateRole, payload);
      if (role.error) {
        // yield put(showAuthMessage(user.error.message));
      } else {
        yield put(updateRoleSuccess(role.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}

export function* getAllRoles() {
  yield takeEvery(GET_ALL_ROLES, function* () {
    try {
      const roles = yield call(idmService.getAllRoles);
      if (roles.error) {
        // yield put(showAuthMessage(user.message));
      } else {
        yield put(getAllRolesSuccess(roles.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}

export function* getAllPrivileges() {
  yield takeEvery(GET_ALL_PRIVILEGES, function* () {
    try {
      const privileges = yield call(idmService.getAllPrivileges);
      if (privileges.error) {
        // yield put(showAuthMessage(user.message));
      } else {
        yield put(getAllPrivilegesSuccess(privileges.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(addRole),
    fork(updateRole),
    fork(getAllRoles),
    fork(getAllPrivileges),
  ]);
}
