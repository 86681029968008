import fetch from 'auth/FetchInterceptor'

const noticeBoardService = {}

noticeBoardService.getNotices = function (params) {
  return fetch({
    url: '/notices',
    method: 'get',
    params
  })
}


noticeBoardService.addNotice = function (data) {
  return fetch({
    url: '/notices',
    method: 'post',
    data: data
  })
}

noticeBoardService.updateNotice = function (id, data) {
  return fetch({
    url: `/notices/${id}`,
    method: 'put',
    data: data
  })
}


export default noticeBoardService