import fetch from 'auth/FetchInterceptor'

const ProjectService = {}

ProjectService.addProject = function (data) {
	return fetch({
		url: '/projects',
		method: 'post',
		data: data
	})

}


ProjectService.updateProject = function (data) {
	return fetch({
		url: '/projects',
		method: 'put',
		data: data
	})

}

ProjectService.getProjects = function (params) {
	return fetch({
	  url: '/projects',
	  method: 'get',
	  params
	})
  }


  ProjectService.getProjectTasks = function (projectId, params) {
	return fetch({
	  url: `/projects/${projectId}/tasks`,
	  method: 'get',
	  headers: {
		"page-request": "true",
	  },
	  params
	})
  }


  ProjectService.getStaffTasks = function (staffId, params) {
	return fetch({
	  url: `/projects/staffs/${staffId}/tasks`,
	  method: 'get',
	  params
	})
  }



  ProjectService.getProjectMembers = function (projectId, params) {
	return fetch({
	  url: `/projects/${projectId}/members`,
	  method: 'get',
	  params
	})
  }



  ProjectService.addProjectTasks = function (data) {
	return fetch({
		url: '/projects/tasks',
		method: 'post',
		data: data
	})

}



ProjectService.updateProjectTasks = function (data) {
	return fetch({
		url: '/projects/tasks',
		method: 'put',
		data: data
	})

}


ProjectService.updateProjectBoard = function (data) {
	return fetch({
		url: '/projects/boards',
		method: 'put',
		data: data
	})

}


ProjectService.updateProjectTasksIndex = function (data) {
	return fetch({
		url: '/projects/tasks/index',
		method: 'put',
		data: data
	})

}



ProjectService.updateProjectBoardsIndex = function (data) {
	return fetch({
		url: '/projects/boards/index',
		method: 'put',
		data: data
	})

}



ProjectService.addProjectBoard = function (data) {
	return fetch({
		url: '/projects/boards',
		method: 'post',
		data: data
	})
}




ProjectService.addTaskComment = function (data) {
	return fetch({
		url: '/projects/tasks/comments',
		method: 'post',
		data: data
	})

}


ProjectService.archiveProject = function (projectId, params) {
	return fetch({
	  url: `/projects/${projectId}`,
	  method: 'delete',
	  params
	})
  }



  ProjectService.archiveBoard = function (boardId, params) {
	return fetch({
	  url: `/projects/boards/${boardId}`,
	  method: 'delete',
	  params
	})
  }



  ProjectService.archiveTask = function (taskId, params) {
	return fetch({
	  url: `/projects/tasks/${taskId}`,
	  method: 'delete',
	  params
	})
  }



  ProjectService.reviewTask = function (data) {
	return fetch({
		url: '/projects/tasks/review',
		method: 'put',
		data: data
	})

}




export default ProjectService