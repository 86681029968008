const y = new Date().getFullYear();

export const memberIds = [
	'dorcas-1153',
	'odamea-1322',
	'esthy7653',
	'philia4721',
	'gloria1384',
	'gideon8493',
	'bisma1263',
	'felicia6532',
	'Elizabeth7953',
	'bright3683',
	'kinglsey564',
	'gabriel2850',
	'anita1941',
	'gailBarnes7615',
	'ellaRobinson1093'
]

export const membersDetail = [
  {
    id: "dorcas-1153",
    name: "Dorcas Sika Tettey",
    email: "data@oti-gati.com",
    img: "/img/avatars/female_avatar.png"
  },
  {
    id: "odamea-1322",
    name: "Amma Odamea Amoako",
    email: "",
    img: "/img/avatars/female_avatar.png"
  },
  {
    id: "esthy7653",
    name: "Esthy Asante",
    email: "export@oti-gati.com",
    img: "/img/avatars/profile-avatar.jpeg"
  },
  {
    id: "philia4721",
    name: "Philia Hammond",
    email: "philiahammond@gmail.com",
    img: "/img/avatars/female_avatar.png"
  },
  {
    id: "gloria1384",
    name: "Gloria Boateng",
    email: "gloria@gmail.com",
    img: "/img/avatars/female_avatar.png"
  },
  {
    id: "gideon8493",
    name: "Gideon Baah",
    email: "baah@gmail.com",
    img: "/img/avatars/male_avatar.png"
  },
  {
    id: "bisma1263",
    name: "Bismark Agyekum",
    email: "bismark@gmail.com",
    img: "/img/avatars/male_avatar.png"
  },
  {
    id: "felicia6532",
    name: "Felicia Manu",
    email: "felicia@gmaill.com",
    img: "/img/avatars/profile-avatar.jpeg"
  },
  {
    id: "Elizabeth7953",
    name: "Elizabeth Nkoom",
    email: "nkoom@gmail.com",
    img: "/img/avatars/thumb-9.jpg"
  },
  {
    id: "bright3683",
    name: "Bright Mensah",
    email: "bright@gmail.com",
    img: "/img/avatars/thumb-10.jpg"
  },
  {
    id: "kinglsey564",
    name: "Kingsly Abban",
    email: "handsome-obrien@hotmail.com",
    img: "/img/avatars/thumb-11.jpg"
  },
  {
    id: "gabriel2850",
    name: "Gabriella May",
    email: "maymaymay12@infotech.io",
    img: "/img/avatars/thumb-12.jpg"
  },
  {
    id: "anita1941",
    name: "Anita Amponsah",
    email: "",
    img: "/img/avatars/thumb-13.jpg"
  },
  {
    id: "gailBarnes7615",
    name: "Gail Barnes",
    email: "gailby0116@infotech.io",
    img: "/img/avatars/thumb-14.jpg"
  },
  {
    id: "ellaRobinson1093",
    name: "Ella Robinson",
    email: "ella_robinson@infotech.io",
    img: "/img/avatars/thumb-15.jpg"
  }
]

export const labels = [
	{
		color: 'blue',
		label: 'Task'
	},
	{
		color: 'gold',
		label: 'Bug'
	},
	{
		color: 'red',
		label: 'Live issue'
	},
	{
		color: 'cyan',
		label: 'Low priority'
	}
];
  

const toBeProcessCards = [
	{
		id: 'zb7zxtjctd',
		name: 'Unable to upload file',
		description: 'Uploading file error. Error when uploading files',
		cover: '/img/others/img-13.jpg',
		assignees: ['dorcas-1153', 'odamea-1322', 'esthy7653'],
		labels: ['Task', 'Live issue'],
		attachments: [
			{
				'id': 'jubuK7XGp3',
				'name': 'mail.jpg',
				'src': '/img/others/Screenshot.png',
				'size': '36.1kb'
			},
			{
				'id': 'xsb3HCejCM',
				'name': 'mail.jpg',
				'src': '/img/others/Screenshot2.png',
				'size': '55.9kb'
			}
		],
		comments: [
			{
				id: 'R22TqMkACm',
				name: 'Esthy Asante',
				src: "/img/avatars/thumb-9.jpg",
				message: 'Uploading file error. Error when uploading files Uploading file error. Error when uploading files Uploading file error. Error when uploading files Uploading file error. Error when uploading files',
				date: new Date(y, 4, 20)
			}
		],
		'dueDate': new Date(y, 7, 5)
	},
	{
		id: '7qgsduurxt',
		name: 'Look for Fonio Workshop Speakers',
		description: '',
		cover: '',
		assignees: ['felicia6532'],
		labels: ['Bug'],
		attachments: [],
		comments: [
			{
				id: 'Wx8FDSsVTg',
				name: 'Dorcas Sika Tettey',
				src: "/img/avatars/thumb-6.jpg",
				message: 'Uploading file error. Error when uploading files Uploading file error. Error when uploading files Uploading file error. Error when uploading files Uploading file error. Error when uploading files',
				date: new Date(y, 4, 20)
			},
			{
				id: '3AhkqqSFFr',
				name: 'Amma Odamea Amoako',
				src: "/img/avatars/profile-avatar.jpeg",
				message: 'AUploading file error. Error when uploading filesm Uploading file error. Error when uploading files Uploading file error. Error when uploading files ',
				date: new Date(y, 4, 20)
			}
		],
		dueDate: new Date(y, 6, 11)
	},
	{
		id: 'wtwgpz6csc',
		name: 'Mothers Day post report',
		description: 'Uploading file error. Error when uploading files Uploading file error. Error when uploading files Uploading file error. Error when uploading files Uploading file error. Error when uploading files',
		cover: '',
		assignees: ['anita1941', 'gloria1384',],
		labels: ['Low priority'],
		attachments: [],
		comments: [
			{
				id: 'bAvBdtKHNC',
				name: 'Esthy Asante',
				src: "/img/avatars/thumb-11.jpg",
				message: 'Uploading file error. Error when uploading files Uploading file error. Error when uploading files Uploading file error. Error when uploading files',
				date: new Date(y, 4, 20)
			}
		],
		'dueDate': new Date(y, 7, 5)
	},
]

const processingCards = [
	{
		id: 'ywejrdr3rn',
		name: 'Interns KPI\'s',
		description: 'Uploading file error. Error when uploading files Uploading file error. Error when uploading files Uploading file error. Error when uploading files Uploading file error. Error when uploading files',
		cover: '',
		assignees: ['bisma1263', 'felicia6532'],
		labels: ['Bug'],
		attachments: [],
		comments: [
			{
				id: 'afPugkEmkp',
				name: 'Esthy Asante',
				src: "/img/avatars/thumb-1.jpg",
				message: '',
				date: new Date(y, 5, 16)
			}
		],
		dueDate: new Date(y, 3, 17)
	},
	{
		id: 'tkBXWJGwkr',
		name: 'Design Logo With Company Name',
		description: 'Uploading file error. Error when uploading files Uploading file error. Error when uploading files Uploading file error. Error when uploading files',
		cover: '',
		assignees: ['bright3683'],
		labels: ['Task'],
		attachments: [
			{
				'id': 'NjHJhHeWrG',
				'name': 'issue.jpg',
				'src': '/img/others/img-16.jpg',
				'size': '46.1kb'
			}
		],
		comments: [
			{
				id: 'MAsPDzGwnA',
				name: 'Dorcas Sika Tettey',
				src: "/img/avatars/profile-avatar.jpeg",
				message: 'Uploading file error. Error when uploading files Uploading file error. Error when uploading files Uploading file error. Error when uploading files Uploading file error. Error when uploading files',
				date: new Date(y, 4, 20)
			}
		],
		dueDate: null
	},
	{
		id: 'VQgUDrYJYH',
		name: 'Create a marketing plan',
		description: '',
		cover: '',
		assignees: ['gideon8493'],
		labels: ['Low priority', 'Task'],
		attachments: [],
		comments: [],
		dueDate: new Date(y, 4, 20)
	}
]

const submittedCards = [
	{
		id: 'jzjn95g3v4',
		name: 'Interns Induction',
		description: 'Uploading file error. Error when uploading files Uploading file error. Error when uploading files Uploading file error. Error when uploading files ',
		cover: '',
		assignees: ['esthy7653'],
		labels: ['Low priority'],
		attachments: [],
		comments: [
			{
				id: 'nBAGhJqe9v',
				name: 'Esthy Asante',
				src: "/img/avatars/profile-avatar.jpeg",
				message: '',
				date: new Date(y, 1, 8)
			}
		],
		dueDate: null
	},
	{
		id: 'ZFQDPmscwA',
		name: 'Intens Job Description',
		description: 'Uploading file error. Error when uploading files Uploading file error. Error when uploading files Uploading file error. Error when uploading files' ,
		cover: '/img/others/Screenshot3.png',
		assignees: ['Elizabeth7953', 'bright3683'],
		labels: ['Live issue'],
		attachments: [
			{
				'id': 'NjHJhHeWrG',
				'name': 'issue.jpg',
				'src': '/img/others/Screenshot3.png',
				'size': '42.6kb'
			}
		],
		comments: [
			{
				id: 'nBAGhJqe9v',
				name: 'Esthy Asante',
				src: "/img/avatars/profile-avatar.jpeg",
				message: '',
				date: new Date(y, 1, 8)
			}
		],
		dueDate: null
	},
]

const completedCards = [
	{
		id: 'yhjk5679xr',
		name: 'Call with Interns',
		description: 'Uploading file error. Error when uploading files Uploading file error. Error when uploading files Uploading file error. Error when uploading files Uploading file error. Error when uploading files',
		cover: '',
		assignees: ['anita1941', 'Elizabeth7953'],
		labels: ['Task'],
		attachments: [],
		comments: [
			{
				id: 'yxc5gwrXUZ',
				name: 'Dorcas Sika Tettey',
				src: "/img/avatars/profile-avatar.jpeg",
				message: '',
				date: new Date(y, 3, 4)
			}
		],
		dueDate: new Date(y, 3, 4)
	},
	{
		id: 'UMgvapYVXm',
		name: 'Complete Mest Assessment form',
		description: 'Uploading file error. Error when uploading files Uploading file error. Error when uploading files Uploading file error. Error when uploading files',
		cover: '',
		assignees: ['gloria1384', 'gideon8493', 'bisma1263'],
		labels: ['Bug'],
		attachments: [],
		comments: [],
		dueDate: new Date(y, 7, 19)
	},
	{
		id: 'uRZNVsCmDW',
		name: 'OTI online Project Plan',
		description: 'Uploading file error. Error when uploading files Uploading file error. Error when uploading files Uploading file error. Error when uploading files ',
		cover: '',
		assignees: ['philia4721'],
		labels: ['Live issue'],
		attachments: [],
		comments: [],
		dueDate: new Date(y, 4, 6)
	},
	{
		id: 'PBSGmhVgvS',
		name: 'OTI online Business case',
		description: 'Uploading file error. Error when uploading files Uploading file error. Error when uploading files Uploading file error. Error when uploading files',
		cover: '',
		assignees: ['gloria1384', 'esthy7653'],
		labels: ['Low priority'],
		attachments: [],
		comments: [
			{
				id: 'dNskbPFeQD',
				name: 'Amma Odamea Amoako',
				src: "/img/avatars/profile-avatar.jpeg",
				message: 'Uploading file error. Error when uploading files Uploading file error. Error when uploading files Uploading file error. Error when uploading files',
				date: new Date(y, 4, 20)
			},
			{
				id: 'qNzkmRZiTO',
				name: 'Dorcas Sika Tettey',
				src: "/img/avatars/thumb-2.jpg",
				message: 'Noted!',
				date: new Date(y, 4, 20)
			}
		],
		dueDate: new Date(y, 7, 13)
	}
]

export const scrumboardData = {
	'New': [...toBeProcessCards],
	'Working On': [...processingCards],
	'Review': [...submittedCards],
	'Completed': [...completedCards]
}