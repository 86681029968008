import {
	SHOW_LOADING,
	ADD_DEPARTMENT_SUCCESS,
	GET_DEPARTMENTS_SUCCESS
} from '../constants/Department';

const initState = {
  loading: false,
  department: null,
  message: '',
  showMessage: false,
  departments: []
}

const department = (state = initState, action) => {
	switch (action.type) {
		case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}

		case ADD_DEPARTMENT_SUCCESS:
			return {
				...state,
				loading: false,
				department: action.department,
				departments: [...state.departments, action.department]
			}

			case GET_DEPARTMENTS_SUCCESS:
				return {
					...state,
					loading: false,
					departments: action.departments
				}
		default:
			return state;
	}
}

export default department