import {
  ADD_PROJECT_TASK,
  GET_PROJECTS_TASKS,
  ADD_PROJECT_TASK_SUCCESS,
  GET_STAFF_TASKS,
  GET_STAFF_TASKS_SUCCESS,
  GET_PROJECTS_TASKS_SUCCESS,
  UPDATE_PROJECT_BOARD,
  UPDATE_PROJECT_BOARD_SUCCESS,
  SHOW_LOADING,
  UPDATE_PROJECT_TASK,
  UPDATE_PROJECT_TASK_SUCCESS,
  UPDATE_PROJECT_TASK_INDEX,
  UPDATE_PROJECT_TASK_INDEX_SUCCESS,
  UPDATE_PROJECT_BOARD_INDEX,
  UPDATE_PROJECT_BOARD_INDEX_SUCCESS,
  ADD_PROJECT_BOARD,
  ADD_PROJECT_BOARD_SUCCESS,
  ADD_TASK_COMMENT,
  ADD_TASK_COMMENT_SUCCESS,
  ARCHIVE_BOARD,
  AARCHIVE_BOARD_SUCCESS,
  ARCHIVE_TASK,
  ARCHIVE_TASK_SUCCESS,
  REVIEW_TASK,
  REVIEW_TASK_SUCCESS,


  UPDATE_ORDERED,
  UPDATE_MODAL,
  UPDATE_MODAL_MODE,
  UPDATE_CURRENT_LIST_ID,
  UPDATE_CARD_DATA,
  UPDATE_COLUMN

} from "../constants/Task";



export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};



export const addProjectTask = (task) => {
  return {
    type: ADD_PROJECT_TASK,
    payload: task,
  };
};

export const addProjectTaskSuccess = (task) => {
  return {
    type: ADD_PROJECT_TASK_SUCCESS,
    task,
  };
};



export const getProjectsTasks = (projectId) => {
  return {
    type: GET_PROJECTS_TASKS,
    projectId
  };
};


export const getProjectsTasksSuccess = (tasks) => {
  return {
    type: GET_PROJECTS_TASKS_SUCCESS,
    tasks
    };
};


export const getStaffTasks = (staffId) => {
  return {
    type: GET_STAFF_TASKS,
    staffId
  };
};

export const getStaffTasksSuccess = (tasks) => {
  return {
    type: GET_STAFF_TASKS_SUCCESS,
    tasks
    };
};


export const updateProjectTask = (task) => {
  return {
    type: UPDATE_PROJECT_TASK,
    payload: task,
  };
};

export const updateProjectTaskSuccess = (task) => {
  return {
    type: UPDATE_PROJECT_TASK_SUCCESS,
    task,
  };
};



export const updateProjectTaskIndex = (task) => {
  return {
    type: UPDATE_PROJECT_TASK_INDEX,
    payload: task,
  };
};

export const updateProjectTaskIndexSuccess = (task) => {
  return {
    type: UPDATE_PROJECT_TASK_INDEX_SUCCESS,
    task,
  };
};




export const updateProjectBoardIndex = (board) => {
  return {
    type: UPDATE_PROJECT_BOARD_INDEX,
    payload: board,
  };
};

export const updateProjectBoardIndexSuccess = (board) => {
  return {
    type: UPDATE_PROJECT_BOARD_INDEX_SUCCESS,
    board,
  };
};



export const updateProjectBoard = (board) => {
  return {
    type: UPDATE_PROJECT_BOARD,
    payload: board,
  };
};

export const updateProjectBoardSuccess = (board) => {
  return {
    type: UPDATE_PROJECT_BOARD_SUCCESS,
    board,
  };
};


export const addProjectBoard = (board) => {
  return {
    type: ADD_PROJECT_BOARD,
    payload: board,
  };
};

export const addProjectBoardSuccess = (board) => {
  return {
    type: ADD_PROJECT_BOARD_SUCCESS,
    board,
  };
};



export const addTaskComment = (comment) => {
  return {
    type: ADD_TASK_COMMENT,
    payload: comment,
  };
};

export const addTaskCommentSuccess = (task) => {
  return {
    type: ADD_TASK_COMMENT_SUCCESS,
    task,
  };
};



export const archiveBoard = (boardId) => {
  return {
    type: ARCHIVE_BOARD,
    boardId,
  };
};

export const archiveBoardSuccess = (board) => {
  return {
    type: AARCHIVE_BOARD_SUCCESS,
    board
    };
};



export const archiveTask = (taskId) => {
  return {
    type: ARCHIVE_TASK,
    taskId,
  };
};

export const archiveTaskSuccess = (task) => {
  return {
    type: ARCHIVE_TASK_SUCCESS,
    task
    };
};



export const reviewTask = (data) => {
  return {
    type: REVIEW_TASK,
    payload: data,
  };
};

export const reviewTaskSuccess = (task) => {
  return {
    type: REVIEW_TASK_SUCCESS,
    task,
  };
};









export const updateOrdered = (ordered)=> {
  return{
    type: UPDATE_ORDERED,
    payload: ordered
  };
}

export const  updateColumns=  (columns)=> {
  return{
    type: UPDATE_COLUMN,
    payload: columns
  };
}
export const  updateModal = (modal)=> {
  return{
    type: UPDATE_MODAL,
    payload: modal
  };
}
export const  updateModalMode = (modalMode)=> {
  return{
    type: UPDATE_MODAL_MODE,
    payload: modalMode
  };
}
export const  updateCurrentListId = (currentListId)=> {
  return{
    type: UPDATE_CURRENT_LIST_ID,
    payload: currentListId
  };
}
export const  updateCardData = (cardData)=> {
 return{
    type: UPDATE_CARD_DATA,
    payload: cardData
  }
}



