import {
  SHOW_LOADING,
  GET_STAFF_NOTIFICATIONS,
  GET_STAFF_NOTIFICATIONS_SUCCESS
} from "../constants/Notification";



export const getStaffNotifications = () => {
  return {
    type: GET_STAFF_NOTIFICATIONS,
  };
};

export const getStaffNotificationsSuccess = (notifications, count) => {
  return {
    type: GET_STAFF_NOTIFICATIONS_SUCCESS,
    notifications,
    count
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};
