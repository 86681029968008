import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADD_PROJECT_TASK,
  GET_PROJECTS_TASKS,
  GET_STAFF_TASKS,
  UPDATE_PROJECT_TASK,
  UPDATE_PROJECT_TASK_INDEX,
  UPDATE_PROJECT_BOARD,
  UPDATE_PROJECT_BOARD_INDEX,
  ADD_PROJECT_BOARD,
  ADD_TASK_COMMENT,
  ARCHIVE_BOARD,
  ARCHIVE_TASK,
  REVIEW_TASK
} from "../constants/Task";
import {
  getProjectsTasksSuccess,
  getStaffTasksSuccess,
  addProjectTaskSuccess,
  updateProjectTaskIndexSuccess,
  updateProjectBoardIndexSuccess,
  updateProjectTaskSuccess,
  updateProjectBoardSuccess,
  addProjectBoardSuccess,
  addTaskCommentSuccess,
  archiveBoardSuccess,
  archiveTaskSuccess,
  reviewTaskSuccess,
  updateModal,
  showLoading
} from "../actions/Task";

import projectService from "services/ProjectService";

export function* addProjectTask() {
  yield takeEvery(ADD_PROJECT_TASK, function* ({ payload }) {
    try {
      yield call(showLoading)
      const task = yield call(projectService.addProjectTasks, payload);
      if (task.error) {
        // yield put(showAuthMessage(user.error.message));
      } else {
        yield put(addProjectTaskSuccess(task.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}

export function* getProjectTasks() {
  yield takeEvery(GET_PROJECTS_TASKS, function* ({ projectId }) {
    try {
      yield call(showLoading)
      const tasks = yield call(projectService.getProjectTasks, projectId);
      if (tasks.error) {
        // yield put(showAuthMessage(user.message));
      } else {
        yield put(getProjectsTasksSuccess(tasks.data));
        yield put(updateModal(false))
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}

export function* getStaffTasks() {
  yield takeEvery(GET_STAFF_TASKS, function* ({ staffId }) {
    try {
      yield call(showLoading)
      const tasks = yield call(projectService.getStaffTasks, staffId);
      if (tasks.error) {
        // yield put(showAuthMessage(user.message));
      } else {
        yield put(getStaffTasksSuccess(tasks.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}

export function* updateProjectTaskIndex() {
  yield takeEvery(UPDATE_PROJECT_TASK_INDEX, function* ({ payload }) {
    try {
      const task = yield call(projectService.updateProjectTasksIndex, payload);
      if (task.error) {
        // yield put(showAuthMessage(user.error.message));
      } else {
        yield put(updateProjectTaskIndexSuccess(task.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}

export function* updateProjectTask() {
  yield takeEvery(UPDATE_PROJECT_TASK, function* ({ payload }) {
    try {
      yield call(showLoading)
      const task = yield call(projectService.updateProjectTasks, payload);
      if (task.error) {
        // yield put(showAuthMessage(user.error.message));
      } else {
        yield put(updateProjectTaskSuccess(task.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}

export function* updateProjectBoard() {
  yield takeEvery(UPDATE_PROJECT_BOARD, function* ({ payload }) {
    try {
      yield call(showLoading)
      const board = yield call(projectService.updateProjectBoard, payload);
      if (board.error) {
        // yield put(showAuthMessage(user.error.message));
      } else {
        yield put(updateProjectBoardSuccess(board.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}

export function* updateProjectBoardIndex() {
  yield takeEvery(UPDATE_PROJECT_BOARD_INDEX, function* ({ payload }) {
    try {
      const board = yield call(projectService.updateProjectBoardsIndex, payload);
      if (board.error) {
        // yield put(showAuthMessage(user.error.message));
      } else {
        yield put(updateProjectBoardIndexSuccess(board.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}



export function* addProjectBoard() {
  yield takeEvery(ADD_PROJECT_BOARD, function* ({ payload }) {
    try {
      yield call(showLoading)
      const board = yield call(projectService.addProjectBoard, payload);
      if (board.error) {
        // yield put(showAuthMessage(user.error.message));
      } else {
        yield put(addProjectBoardSuccess(board.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}



export function* addTaskComment() {
  yield takeEvery(ADD_TASK_COMMENT, function* ({ payload }) {
    try {
      const comment = yield call(projectService.addTaskComment, payload);
      if (comment.error) {
        // yield put(showAuthMessage(user.error.message));
      } else {
        yield put(addTaskCommentSuccess(comment.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}



export function* archiveBoard() {
  yield takeEvery(ARCHIVE_BOARD, function* ({ boardId }) {
    try {
      const board = yield call(projectService.archiveBoard, boardId);
      if (board.error) {
        // yield put(showAuthMessage(user.error.message));
      } else {
        yield put(archiveBoardSuccess(board.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}



export function* archiveTask() {
  yield takeEvery(ARCHIVE_TASK, function* ({ taskId }) {
    try {
      const task = yield call(projectService.archiveTask, taskId);
      if (task.error) {
        // yield put(showAuthMessage(user.error.message));
      } else {
        yield put(archiveTaskSuccess(task.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}



export function* reviewTask() {
  yield takeEvery(REVIEW_TASK, function* ({ payload }) {
    try {
      const task = yield call(projectService.reviewTask, payload);
      if (task.error) {
        // yield put(showAuthMessage(user.error.message));
      } else {
        yield put(reviewTaskSuccess(task.data));
      }
    } catch (err) {
      console.log(err);
      // yield put(showAuthMessage(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(addProjectTask),
    fork(getProjectTasks),
    fork(updateProjectTaskIndex),
    fork(updateProjectTask),
    fork(updateProjectBoard),
    fork(updateProjectBoardIndex),
    fork(addProjectBoard),
    fork(addTaskComment),
    fork(getStaffTasks),
    fork(archiveBoard),
    fork(archiveTask),
    fork(reviewTask)
  ]);
}
