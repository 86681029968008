import {
    ADD_ROLE,
    ADD_ROLE_SUCCESS,
    SHOW_LOADING,
    UPDATE_ROLE,
    UPDATE_ROLE_SUCCESS,
    GET_ALL_PRIVILEGES,
    GET_ALL_PRIVILEGES_SUCCESS,
    GET_ALL_ROLES,
    GET_ALL_ROLES_SUCCESS
  } from "../constants/Idm";
  
  export const addRole = (role) => {
    return {
      type: ADD_ROLE,
      payload: role,
    };
  };
  
  export const addRoleSuccess = (role) => {
    return {
      type: ADD_ROLE_SUCCESS,
      role,
    };
  };


  export const updateRole = (role) => {
    return {
      type: UPDATE_ROLE,
      payload: role,
    };
  };
  
  export const updateRoleSuccess = (role) => {
    return {
      type: UPDATE_ROLE_SUCCESS,
      role,
    };
  };
  
  export const getAllRoles = () => {
    return {
      type: GET_ALL_ROLES,
    };
  };
  
  export const getAllRolesSuccess = (roles) => {
    return {
      type: GET_ALL_ROLES_SUCCESS,
      roles,
    };
  };



  export const getAllPrivileges = () => {
    return {
      type: GET_ALL_PRIVILEGES,
    };
  };
  
  export const getAllPrivilegesSuccess = (privileges) => {
    return {
      type: GET_ALL_PRIVILEGES_SUCCESS,
      privileges,
    };
  };



  
  export const showLoading = () => {
    return {
      type: SHOW_LOADING,
    };
  };
  