import {
  SHOW_LOADING,
  GET_ROOM_CHATS_SUCCESS,
  GET_USER_ROOMS_SUCCESS,
  NEW_MESSAGE_RECEIVED,
  JOINED_NEW_ROOM,
  MARK_CHATROOM_MESSAGES_READ,
  MARK_CHATROOM_MESSAGES_SENT,
  UPDATED_MESSAGE_RECEIVED,
  REMOVE_MESSAGE_FROM_CHAT,
} from "../constants/Chat";

const initState = {
  loading: false,
  chats: [],
  showMessage: false,
  rooms: [],
};

const chat = (state = initState, action) => {
  switch (action.type) {
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_USER_ROOMS_SUCCESS:
      return {
        ...state,
        loading: false,
        rooms: action.rooms,
      };

    case GET_ROOM_CHATS_SUCCESS:
      return {
        ...state,
        loading: false,
        chats: action.chats,
      };

    case NEW_MESSAGE_RECEIVED:
      let rooms = [...state.rooms];
      let message = action.message;
      message.chatRoom = message.chatRoom.id;

      let roomIndex = rooms.findIndex(
        (room) => `${room.id}` === `${message.chatRoom}`
      );

      let oldRoom = Object.assign({}, rooms[roomIndex]);

      let newRoom = {
        ...oldRoom,
        messages: oldRoom.messages ? [...oldRoom.messages, message] : [message],
      };

      rooms = rooms.filter((v, i) => i !== roomIndex);
      let newRooms = [newRoom, ...rooms];

      return {
        ...state,
        rooms: newRooms,
      };

    case JOINED_NEW_ROOM:
      action.room.messages = [];
      return {
        ...state,
        rooms: [action.room, ...state.rooms],
      };

    case MARK_CHATROOM_MESSAGES_READ:
      let roomsWithUpdatedReadMessages = state.rooms.map((room) => {
        if (room.id === action.chatRoomId) {
          room.unread = 0;
        }
        return room;
      });

      return {
        ...state,
        rooms: roomsWithUpdatedReadMessages,
      };

    case UPDATED_MESSAGE_RECEIVED:
      let roomsWithEditMessages = state.rooms.map((room) => {
        if (room.id === action.message.chatRoom.id) {
          const newMessages = room.messages.map((message) => {
            if (message.id === action.message.id) {
              return action.message;
            } else {
              return message;
            }
          });

          room.messages = newMessages;
        }
        return room;
      });

      case MARK_CHATROOM_MESSAGES_SENT:
        let roomsWithUpdatedMessage = state.rooms.map((room) => {
          if (room.id === action.message.chatRoom.id) {
            const newMessages = room.messages.map((message) => {
              if (message.key === action.message.key) {
                return action.message;
              } else {
                return message;
              }
            });
  
            room.messages = newMessages;
          }
          return room;
        });

      return {
        ...state,
        rooms: roomsWithUpdatedMessage,
      };

    case REMOVE_MESSAGE_FROM_CHAT:
      let roomsWithoutDeletedMessage = state.rooms.map((room) => {
        if (room.id === action.message.chatRoomId) {
          const newMessages = room.messages.filter((message) => {
            if (message.id === action.message.messageId) {
              return false;
            } else {
              return true;
            }
          });

          room.messages = newMessages;
        }
        return room;
      });

      return {
        ...state,
        rooms: roomsWithoutDeletedMessage,
      };

    default:
      return state;
  }
};

export default chat;
