import fetch from 'auth/FetchInterceptor'

const IdmService = {}

IdmService.addRole = function (data) {
	return fetch({
		url: '/idm/roles',
		method: 'post',
		data: data
	})

}

IdmService.updateRole = function (data) {
	console.log(data)
	return fetch({
	  url: '/idm/roles',
	  method: 'put',
      data: data
	})
  }



  IdmService.getAllRoles = function (params) {
	return fetch({
	  url: '/idm/roles',
	  method: 'get',
      params
	})
  }



  IdmService.getAllPrivileges = function (params) {
	return fetch({
	  url: '/idm/privileges',
	  method: 'get',
      params
	})
  }



export default IdmService