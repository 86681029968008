
export const SHOW_LOADING = 'SHOW_LOADING'

export const GET_PROJECTS_TASKS = 'GET_PROJECTS_TASKS'
export const GET_PROJECTS_TASKS_SUCCESS = 'GET_PROJECTS_TASKS_SUCCESS'



export const GET_STAFF_TASKS = 'GET_STAFF_TASKS'
export const GET_STAFF_TASKS_SUCCESS = 'GET_STAFF_TASKS_SUCCESS'


export const ADD_PROJECT_TASK = 'ADD_PROJECT_TASK'; 
export const ADD_PROJECT_TASK_SUCCESS = 'ADD_PROJECT_TASK_SUCCESS'

export const UPDATE_PROJECT_TASK = 'UPDATE_PROJECT_TASK'; 
export const UPDATE_PROJECT_TASK_SUCCESS = 'UPDATE_PROJECT_TASK_SUCCESS'



export const UPDATE_PROJECT_TASK_INDEX = 'UPDATE_PROJECT_TASK_INDEX'; 
export const UPDATE_PROJECT_TASK_INDEX_SUCCESS = 'UPDATE_PROJECT_TASK_INDEX_SUCCESS'



export const UPDATE_PROJECT_BOARD_INDEX = 'UPDATE_PROJECT_BOARD_INDEX'; 
export const UPDATE_PROJECT_BOARD_INDEX_SUCCESS = 'UPDATE_PROJECT_BOARD_INDEX_SUCCESS'


export const UPDATE_ORDERED = 'UPDATE_ORDERED'
export const UPDATE_COLUMN = 'UPDATE_COLUMN'
export const UPDATE_MODAL = 'UPDATE_MODAL'
export const UPDATE_MODAL_MODE = 'UPDATE_MODAL_MODE'
export const UPDATE_CURRENT_LIST_ID = 'UPDATE_CURRENT_LIST_ID'
export const UPDATE_CARD_DATA = 'UPDATE_CARD_DATA'



export const UPDATE_PROJECT_BOARD = "UPDATE_PROJECT_BOARD"
export const UPDATE_PROJECT_BOARD_SUCCESS = "UPDATE_PROJECT_BOARD_SUCCESS"


export const ADD_PROJECT_BOARD = 'ADD_PROJECT_BOARD'; 
export const ADD_PROJECT_BOARD_SUCCESS = 'ADD_PROJECT_BOARD_SUCCESS'




export const ADD_TASK_COMMENT = "ADD_TASK_COMMENT"
export const ADD_TASK_COMMENT_SUCCESS = "ADD_TASK_COMMENT_SUCCESS"



export const ARCHIVE_BOARD = 'ARCHIVE_BOARD'; 
export const AARCHIVE_BOARD_SUCCESS = 'AARCHIVE_BOARD_SUCCESS'




export const ARCHIVE_TASK = 'ARCHIVE_TASK'; 
export const ARCHIVE_TASK_SUCCESS = 'ARCHIVE_TASK_SUCCESS'


export const REVIEW_TASK = 'REVIEW_TASK'; 
export const REVIEW_TASK_SUCCESS = 'REVIEW_TASK_SUCCESS'





