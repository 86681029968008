import fetch from 'auth/FetchInterceptor'

const ChatService = {}

ChatService.initiateChat = function (data) {
	return fetch({
		url: '/chats/rooms',
		method: 'post',
		data: data
	})

}

ChatService.getUserRooms = function (userId, params) {
	return fetch({
	  url: `/chats/rooms?memberId=${userId}`,
	  method: 'get',
	  params
	})
  }


  ChatService.getRoomChats = function (roomId, params) {
	return fetch({
	  url: `/chats/roomId=${roomId}`,
	  method: 'get',
	  params
	})
  }



export default ChatService